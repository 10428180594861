import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const ExpandableSection = ({ title, description }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="text-blue-900 my-2 w-full border-b-2">
            <div className="my-4 flex items-center cursor-pointer font-semibold leading-relaxed text-xl text-blue-900" onClick={() => setIsExpanded(!isExpanded)}>
                <h2 className=' text-base'>{title}</h2>
                {
                    isExpanded ? <AiOutlineMinus className='ml-auto' /> : <AiOutlinePlus className='ml-auto' />
                }
            </div>
            {isExpanded && <div className="break-words w-full mb-2">{description}</div>}
        </div>
    );
};

export default ExpandableSection;


