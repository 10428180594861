import { useRef } from "react";
import { Link } from "react-router-dom";
import { HOME } from "../../constants/routes";
import { SmallLogo } from "../Logo";
import { AiOutlineHome } from "react-icons/ai";

function HeaderHeroNavigation({ heroTitle, heroSubtitle, user }) {
    const navRef = useRef(null);
    const navContent = useRef(null);

    const toggleNav = () => {
        navContent.current.classList.toggle("hidden");
    }

    return (<div className="font-inter">
        <nav className="bg-gradient-to-r bg-gray-50 flex items-center justify-between flex-wrap bg-grey-darkest p-6 md:px-10">
            <div className="flex items-center flex-no-shrink text-white md:pr-6">
                <Link
                    className="text-white no-underline hover:text-white hover:no-underline"
                    to={HOME}>
                    <SmallLogo />
                </Link>
            </div>
            {user ? <Link
                className="flex bg-gradient-to-r border border-crayola-800 from-crayola-700 to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-1 px-2"
                to={HOME}>
                <div className="flex items-center"><AiOutlineHome className="mr-1" />Dashboard</div>
            </Link> : <></>}

        </nav>
        <div className="bg-gray-50 flex flex-col justify-center items-center flex-wrap bg-grey-darkest p-6">
            <h1 className="text-4xl font-bold text-center text-blue-900">{heroTitle}</h1>
            <p className="text-xl mb-12 mt-4 text-blue-900 md:mx-40 text-center">{heroSubtitle}</p>
        </div>
    </div>)
}


export default HeaderHeroNavigation