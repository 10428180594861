import { firestoreDB } from "../../api/Firebase";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from '../Session';
import Loading from "../Loading/loadingIndicator";

const url = `${process.env.REACT_APP_BASE_URL}/check-subscription/`

export const usePremiumUser = () => {

    const { initializing, user } = useAuth();
    const [stripeCustomerId, setStripeCustomerId] = useState(null);
    const [premiumUser, setPremiumUser] = useState(null);

    useEffect(() => {
        if (stripeCustomerId !== null) {
            const body = {
                stripeCustomerID: stripeCustomerId
            };

            const headers = {
                "Accept": "text/html; charset=UTF-8",
                userUID: user.uid,
                authorization: "Bearer " + user.accessToken
            }

            axios.post(url, body, { headers: headers })
                .then(res => {
                    console.log(res)
                    if (res.data.code === 0) {
                        setPremiumUser(previous => {
                            return {
                                ...previous,
                                isPremiumUser: true,
                                subscription: res.data.data.subscription
                            }
                        });

                    } else if (res.data.code === 1) {
                        setPremiumUser(previous => {
                            return {
                                ...previous,
                                isPremiumUser: false,
                                subscription: null
                            }
                        });
                    }
                }).catch(e => {
                    setPremiumUser(previous => {
                        return {
                            ...previous,
                            isPremiumUser: false,
                            subscription: null
                        }
                    });
                })
        }
    }, [stripeCustomerId])

    useEffect(() => {
        if (user !== null) {
            const docRef = doc(firestoreDB, "users", user.uid);
            const docSnap = getDoc(docRef);
            docSnap.then((doc) => {
                if (doc.exists()) {
                    setStripeCustomerId(() => doc.data().stripeCustomerID)
                } else {
                    setPremiumUser(previous => {
                        return {
                            ...previous,
                            isPremiumUser: false,
                            subscription: null
                        }
                    });
                }
            }).catch((e) => {
                setPremiumUser(previous => {
                    return {
                        ...previous,
                        isPremiumUser: false,
                        subscription: null
                    }
                });
            })
        }
    }, [user])

    return premiumUser;
}
