import { AiOutlineDownload, AiOutlineFileImage } from "react-icons/ai"
import transparentBackground from "../../../../assets/tools/transparent-background.png"

export const RemoveBGPreview = ({ productAsset, images, downloadImage }) => {

    return (
        <div className="h-full overflow-y-auto flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 md:h-4/5 my-2 md:my-0 md:mx-2 rounded-lg">
                {productAsset != null && (productAsset.imageBase64 || productAsset.demoProductUrl) ? (
                    <img
                        src={productAsset.isDemoProduct ? productAsset.demoProductUrl : productAsset.imageBase64}
                        alt="Preview"
                        className="object-cover w-full h-full rounded-lg"
                    />
                ) : (
                    <div className="flex w-full h-96 md:h-full text-center relative rounded-lg bg-blue-50">
                        <div className='mx-auto my-auto'>
                            <div className='flex items-center text-blue-900'>
                                <AiOutlineFileImage size={58} />
                                <p>No image added</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>


            <div style={{ backgroundImage: `url(${transparentBackground})` }}
                className="relative bg-contain bg-center w-full md:w-1/2 h-96 md:h-4/5 my-2 md:my-0 md:mx-2 rounded-lg">
                {images[0] && <div>
                    <img
                        src={images[0]}
                        alt="After"
                        className="object-contain w-full absolute top-0 left-0 h-full"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 hover:opacity-100 transition-opacity rounded-lg">
                        <button onClick={() => downloadImage(images[0], "remove-bg")} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded text-base font-semibold">
                            <AiOutlineDownload size={18} className="text-white mr-1" />Download
                        </button>
                    </div>
                </div>}
            </div>
        </div>

        // <div className="w-full h-full my-0 flex flex-col bg-white overflow-hidden">
        //     <div className="md:h-4/5 flex flex-col md:flex-row justify-around items-stretch my-4">
        //         <div className="flex-grow md:w-1/2 bg-red-100 mx-2 rounded-lg">
        //             {productAsset != null && (productAsset.imageBase64 || productAsset.demoProductUrl) ? (
        //                 <img
        //                     src={productAsset.isDemoProduct ? productAsset.demoProductUrl : productAsset.imageBase64}
        //                     alt="Preview"
        //                     className="object-cover w-full h-full rounded-lg"
        //                 />
        //             ) : (
        //                 <div className="flex w-full h-full text-center relative rounded-lg bg-blue-50">
        //                     <div className='mx-auto my-auto'>
        //                         <div className='flex items-center text-blue-900'>
        //                             <AiOutlineFileImage size={58} />
        //                             <p>No image added</p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             )}
        //         </div>
        //         <div
        //             style={{ backgroundImage: `url(${transparentBackground})` }}
        //             className="flex-grow md:w-1/2 bg-contain bg-center w-full h-full rounded-lg relative mx-2"
        //         >
        //             {images[0] && <div>
        //                 <img
        //                     src={images[0]}
        //                     alt="After"
        //                     className="object-contain w-full absolute top-0 left-0 h-full"
        //                 />
        //                 <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 hover:opacity-100 transition-opacity rounded-lg">
        //                     <button onClick={() => downloadImage(images[0])} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded text-base font-semibold">
        //                         <AiOutlineDownload size={18} className="text-white mr-1" />Download
        //                     </button>
        //                 </div>
        //             </div>}
        //         </div>
        //     </div>
        // </div>
    )
}
