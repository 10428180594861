import { AiOutlineDownload } from "react-icons/ai"

export const InstantBGEditorPreview = ({ productAsset, images, downloadImage, handleImageClick }) => {

    return <div className="flex-grow bg-white overflow-hidden no-scrollbar my-2">
        <div className="md:h-3/5 flex flex-col lg:flex-row justify-around ">
            <div className="flex w-full lg:w-1/2 text-center relative rounded-lg">
                {productAsset.imageBase64 || productAsset.demoProductUrl ? (
                    <img
                        src={productAsset.isDemoProduct ? productAsset.demoProductUrl : productAsset.imageBase64}
                        alt="Preview"
                        className="object-contain w-full rounded-lg"
                    />
                ) : <div className="flex w-full m-2 text-center relative rounded-lg bg-blue-50">
                    <div className='mx-auto my-auto'>
                        <div className='flex'>
                            <div className='flex items-center justify-center w-6 h-6 text-sm rounded-full bg-blue-400 text-blue-50'> <span>1</span></div>
                            <p className='text-sm text-blue-800 ml-2'>Add a default product image</p>
                        </div>
                        <div className='flex my-2'>
                            <div className='flex items-center justify-center w-6 h-6 text-sm rounded-full bg-blue-400 text-blue-50'> <span>2</span></div>
                            <p className='text-sm text-blue-800 ml-2'>Choose a prompt template or enter new prompt.</p>
                        </div>
                        <div className='flex my-2'>
                            <div className='flex items-center justify-center w-6 h-6 text-sm rounded-full bg-blue-400 text-blue-50'> <span>3</span></div>
                            <p className='text-sm text-blue-800 ml-2'>Select the number of image results.</p>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div className="flex w-full lg:w-1/2  relative cursor-pointer rounded-lg">
                {images.length > 0 ? (
                    <>
                        <img
                            src={images[0]}
                            alt="Preview"
                            className="w-full object-contain rounded-lg"
                        />

                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 hover:opacity-100 transition-opacity rounded-lg">
                            <button onClick={() => downloadImage(images[0], "instant-bg")} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded text-base font-semibold">
                                <AiOutlineDownload size={18} className="text-white mr-1" />Download
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="object-cover h-4/6 w-full rounded-lg opacity-0"></div>
                )}
            </div>
        </div>
        <div className="h-58 md:h-1/4 mt-10 flex justify-center ">
            <div className="w-full md:w-3/5 flex justify-around ">
                {images.slice(1, 4).map((image, index) => (
                    <div key={index} className="md:w-1/3 h-full mx-2 rounded-lg overflow-hidden cursor-pointer hover:scale-110">
                        <img
                            src={image}
                            alt="Preview"
                            className=" w-full h-full object-cover rounded-lg"
                            onClick={() => handleImageClick(index + 1)}
                        />
                    </div>
                ))}
            </div>
        </div>
    </div >
}
