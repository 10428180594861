import { BsFillPatchCheckFill } from 'react-icons/bs'
import TopNavigation from "../TopNavigation";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { usePremiumUser } from '../User/premiumUser';
import * as ROUTES from "./../../constants/routes"

function OrderSuccess() {

    const navigate = useNavigate();
    const premiumUser = usePremiumUser()

    useEffect(() => {

    }, [premiumUser])

    const goToDashboard = (e) => {
        e.preventDefault();
        navigate(ROUTES.HOME)
    }

    return (
        <div className='flex flex-row h-screen'>
            <TopNavigation />
            <div className="container mx-auto">
                <div className="flex flex-col mx-2 md:mx-6 lg:mx-52 my-20 border border-blue-100 rounded-md p-4 text-blue-900 items-center text-center">
                    <BsFillPatchCheckFill className="text-5xl text-green-700 mt-8 mb-4" />
                    <h1 className="font-bold text-2xl my-2">Premium Subscription Confirmed!</h1>
                    <p className="font-semibold text-xl my-1">Thank you for subscribing and welcome to Wordlab</p>
                    <p>As a premium user you can now enjoy the benefits of our service.</p>
                    <p>Go on and create a few product descriptions.</p>
                    <button onClick={goToDashboard} className="bg-gradient-to-r from-crayola-600 to to-crayola-700 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-2 px-4 mt-10">Go to Dashboard</button>
                </div>
            </div>
        </div >
    );
}

export default OrderSuccess;