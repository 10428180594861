import { BsStar } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import { useGetAvailableCredits } from "../User/availableCredits"
import { usePremiumUser } from "../User/premiumUser"
import * as ROUTES from "./../../constants/routes"
import { FaWallet } from "react-icons/fa"
import { useGetAvailableImageCredits } from "../User/getAvailableImageCredits"

function CurrentPlan() {

    const premiumUser = usePremiumUser()
    const availableCredits = useGetAvailableCredits()
    const availableImageCredits = useGetAvailableImageCredits()
    const navigate = useNavigate()

    const goToPricing = (e) => {
        e.preventDefault();
        navigate(ROUTES.PRICING)
    }

    return (
        <div>
            <div className="flex my-4 lg:mt-80 ">
                <div className="flex flex-col items-center">
                    <button onClick={goToPricing} className="flex items-center bg-gradient-to-r from-crayola-600  to-crayola-800  hover:bg-gradient-to-r hover:from-crayola-800  hover:to-crayola-900 py-1 px-6 rounded-full text-white text-sm mx-2">
                        <BsStar className="mx-1 my-2" />
                        Add credits
                    </button>
                    <div className="w-full flex justify-center items-center mt-4 text-blue-900 ">
                        <FaWallet className="" />
                        <p className="font-semibold text-sm ml-2">
                            Credits:
                        </p>
                        <p className="text-sm ml-1">{availableImageCredits}</p>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CurrentPlan