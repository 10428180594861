import logo from '../../assets/logo-2.svg';

const SmallLogo = () => {
    return (
        <a href='https://wordlab.io' className='text-blue-900 flex items-center text-2xl'>
            <img className="h-6" src={logo} alt=" Logo" />
            <h1>Wordlab</h1>
        </a>
    );
}

const MediumLogo = () => {
    return (
        <a href='https://wordlab.io' className='text-white flex items-center text-3xl'>
            <img className="h-12 mx-1" src={logo} alt=" Logo" />
            <h1>Wordlab</h1>
        </a>
    );
}

const LargeLogo = () => {
    return (
        <a href='https://wordlab.io' className='text-blue-900 flex items-center text-4xl'>
            <img className="h-10 mx-1" src={logo} alt=" Logo" />
            <h1>Wordlab</h1>
        </a>
    );
}

export {
    SmallLogo,
    MediumLogo,
    LargeLogo
};