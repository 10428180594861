import { useState } from "react";

const payAsYouGo20 = process.env.REACT_APP_PRICE_ID_20
const payAsYouGo50 = process.env.REACT_APP_PRICE_ID_50
const payAsYouGo150 = process.env.REACT_APP_PRICE_ID_150
const payAsYouGo350 = process.env.REACT_APP_PRICE_ID_350
const payAsYouGo850 = process.env.REACT_APP_PRICE_ID_850
const payAsYouGo1990 = process.env.REACT_APP_PRICE_ID_1990
const payAsYouGo6500 = process.env.REACT_APP_PRICE_ID_6500
const payAsYouGo14150 = process.env.REACT_APP_PRICE_ID_14150
const payAsYouGo35000 = process.env.REACT_APP_PRICE_ID_35000

const payAsYouGoPlan = {
    "planId": "pay-as-you-go",
    "planName": "Pay as you go",
    "description": "",
    "plan": [
        // { credits: "50", totalPrice: "4.99", pricePerImage: "0.10", selected: true, priceId: payAsYouGo20 },
        { id: 1, credits: "125", totalPrice: "10", pricePerImage: "0.08", selected: true, priceId: payAsYouGo50 },
        // { credits: "150", totalPrice: "24", pricePerImage: "0.16", selected: false, priceId: payAsYouGo150 },
        { id: 2, credits: "820", totalPrice: "49", pricePerImage: "0.06", selected: false, priceId: payAsYouGo350 },
        { id: 3, credits: "2475", totalPrice: "99", pricePerImage: "0.04", selected: false, priceId: payAsYouGo850 },
        { id: 4, credits: "9950", totalPrice: "199", pricePerImage: "0.02", selected: false, priceId: payAsYouGo1990 },
        { id: 5, credits: "50000", totalPrice: "499", pricePerImage: "0.01", selected: false, priceId: payAsYouGo6500 },
        // { credits: "14150", totalPrice: "849", pricePerImage: "0.06", selected: false, priceId: payAsYouGo14150 },
        // { credits: "35000", totalPrice: "1400", pricePerImage: "0.04", selected: false, priceId: payAsYouGo35000 },
    ],
}

function PayAsYouGoPlan({ handleSubscribe, isLoading }) {

    const [selectedPlan, setSelectedPlan] = useState(() => {
        return payAsYouGoPlan.plan.find(p => p.selected) || null;
    });

    const handleRadioChange = (plan) => {
        setSelectedPlan(plan);
    }

    const handleBuyNow = (e) => {
        handleSubscribe(e, selectedPlan);
    }

    return (
        <div className="mb-6 mx-2 lg:mb-0 lg:w-1/3">
            <div className="block rounded-lg shadow-lg bg-white h-full">
                <div className="p-6 border-b border-gray-300 text-center">
                    <h2 className='text-2xl font-semibold font-inter text-slate-800'>Pay as you go</h2>
                    <div className="flex justify-center items-center mt-4 text-blue-900">
                        <h3 className=" text-5xl font-bold">$ {selectedPlan.pricePerImage} </h3>
                        <span className="ml-1 text-sm font-bold">/ image</span>
                    </div>
                </div>
                {payAsYouGoPlan.plan.map((price) => (
                    <div key={price.id} className='grid grid-cols-[2fr,1fr,2fr] text-start m-4'>
                        <div className='flex items-center'>
                            <input
                                type="radio"
                                name="priceOption"
                                value={price.credits}
                                className="w-6 h-6 cursor-pointer"
                                checked={selectedPlan && selectedPlan.credits === price.credits}
                                onChange={() => handleRadioChange(price)}
                            />
                            <p className="ml-2 text-sm md:text-md">{price.credits} Credits</p>
                        </div>
                        <p>$ {price.totalPrice}</p>
                        <strong className="text-sm md:text-md">$ {price.pricePerImage} / image</strong>
                    </div>
                ))}
                <div className="pt-4 px-4">
                    <button onClick={handleBuyNow} className='w-full bg-gradient-to-r from-crayola-700 to to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-2 px-4' disabled={isLoading}>
                        {isLoading ? <div className='flex justify-center items-center'>
                            <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span class="font-medium">Loading... </span>
                        </div> : 'Buy Now'}
                    </button>
                </div>
                <p className="text-center text-sm my-2">$ {selectedPlan.totalPrice} one-time payment, price incl. VAT, if applicable</p>
                <div className="flex h-14 justify-center items-center bg-gray-100 text-slate-500 text-sm">
                    <p>Credits available for use anytime.</p>
                </div>
            </div>
        </div>
    );
}

export default PayAsYouGoPlan