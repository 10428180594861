import { AiOutlineFileImage } from "react-icons/ai";
import { Link } from "react-router-dom";
import { SIGN_IN } from "../../constants/routes";

function FreePlan({ user }) {
    return (
        <div className="mb-6 mx-2 lg:mb-0 lg:w-1/3">
            <div className="block rounded-lg shadow-lg bg-white h-full">
                <div className="p-6 text-center h-1/5">
                    <h2 className='text-2xl font-semibold font-inter text-slate-800'>Free</h2>
                </div>
                <div className="flex justify-center items-center">
                    <div className="p-6 flex flex-col">
                        <div className="flex justify-center items-center w-full">
                            <AiOutlineFileImage size={50} />
                        </div>
                        <div className="flex justify-center items-center w-full mt-4 font-semibold">
                            <p>10 credits per month</p>
                        </div>
                        {!user ?
                            <Link
                                className='w-full bg-gradient-to-r text-center from-crayola-700 to to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none mt-40 py-2 px-4'
                                to={SIGN_IN}>
                                <div className="flex items-center justify-center">Get Started For Free</div>
                            </Link> : <></>}
                    </div>
                </div>


            </div>
        </div>
    );
}

export default FreePlan;