
const token = process.env.REACT_APP_MIXPANEL_TOKEN

const configs = {
    development: {
        mixpanelKey: token,
        debug: true,
    },
    production: {
        mixpanelKey: token,
        debug: false,
    },
};

const env = process.env.NODE_ENV || 'development';
export const { mixpanelKey, debug } = configs[env];
