import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../Session';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../constants/routes"
import Modal from '../Modal';
import { useGetStripeCustomerID } from '../User/getStripeCustomerID';
import { saveAs } from 'file-saver';
import { useGetAvailableImageCredits } from '../User/getAvailableImageCredits';
import { BsChatDots, BsStar } from 'react-icons/bs';
import { InstantBGEditor } from './tools/photos/InstantBGEditor';
import removeBGImage from "../../assets/tools/remove-bg-before-after.png"
import instantBGImage from "../../assets/tools/instant-bg-before-after.png"
import colorBGImage from "../../assets/tools/solid-bg-before-after.png"
import { InstantBGEditorPreview } from './tools/photos/InstantBGEditorPreview';
import { ColorBGEditor } from './tools/photos/ColorBGEditor';
import { ColorBGPreview } from './tools/photos/ColorBGPreview';
import { RemoveBGEditor } from './tools/photos/RemoveBGEditor';
import { RemoveBGPreview } from "./tools/photos/RemoveBGPreview"
import { AiOutlineCrown } from 'react-icons/ai';
import { trackEvent } from '../../api/mixpanel';

const generateProductImageURL = `${process.env.REACT_APP_BASE_URL}/convert-image/`
const uploadImageURL = `${process.env.REACT_APP_BASE_URL}/upload-image/`

const editorTools = [
    {
        "title": "Photos",
        "tools": [
            { "type": "instant-bg", "title": "Instant Background", "src": instantBGImage, "isPro": true },
            { "type": "remove-bg", "title": "Remove Background", "src": removeBGImage, "isPro": false },
            { "type": "color-bg", "title": "Color Background", "src": colorBGImage, "isPro": true }
        ]
    },
    // {
    //     "title": "Text",
    //     "tools": [
    //         { "title": "Instant BG", "src": instantBGImage },
    //         { "title": "Remove BG", "src": removeBGImage },
    //         { "title": "Color BG", "src": solidBGImage }
    //     ]
    // }
]

function Editor() {

    const navigate = useNavigate()
    const { initializing, user } = useAuth()
    const [isLoading, setIsLoading] = useState(false)

    const availableImageCredits = useGetAvailableImageCredits()
    const stripeCustomerID = useGetStripeCustomerID()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState({
        title: "Add more credits",
        subTitle: "You do not have any available credits to generate more images. Please add more credits to generate up to 4 images simultaneously."
    })

    const [currentTool, setCurrentTool] = useState("default")

    const [productAsset, setProductAsset] = useState({
        imageBase64: null,
        name: "",
        isDemoProduct: false,
        demoProductUrl: null
    });

    const [images, setImages] = useState([]);

    const onDismissClicked = () => {
        setIsModalOpen(false)
    }

    const goBack = (e) => {
        setImages([])
        setProductAsset({
            ...productAsset,
            imageBase64: null,
            name: "",
            isDemoProduct: false,
            demoProductUrl: null
        })
        setCurrentTool("default")
    }

    const onUpgradeClicked = () => {
        setIsModalOpen(false)
        navigate(ROUTES.PRICING)
    }

    const handleDemoFileSelected = (imageUrl) => {
        setProductAsset({
            ...productAsset,
            demoProductUrl: imageUrl,
            isDemoProduct: true
        });
    }

    const handleFileSelected = (base64) => {
        if (base64 != null) {
            setProductAsset({
                ...productAsset,
                imageBase64: base64,
                demoProductUrl: null,
                isDemoProduct: false
            });
        } else {
            setProductAsset({
                ...productAsset,
                imageBase64: base64,
                demoProductUrl: null,
                isDemoProduct: false
            });
        }
    };

    const generateImages = async (product) => {
        if (availableImageCredits === 0) {
            setIsModalOpen(true)
            return;
        }

        if (availableImageCredits < product.imageNumber) {
            setModalInfo({
                ...modalInfo,
                subTitle: `You need at least ${product.imageNumber} credits to generate ${product.imageNumber} images. Please add more credits.`
            })
            setIsModalOpen(true)
            return;
        }

        setIsLoading(true)
        if (productAsset.isDemoProduct) {
            generateProductImages(productAsset.demoProductUrl, product)
        } else {
            const config = {
                headers: {
                    useruid: user.uid,
                    authorization: `Bearer ${user.accessToken} `
                }
            }

            const result = removeBase64Prefix(productAsset.imageBase64)

            const body = {
                base64Image: result,
            }

            await axios.post(
                uploadImageURL,
                body,
                config
            ).then((response) => {
                generateProductImages(response.data.imageUrl, product)
            }).catch((error) => {
                setIsLoading(false);
            }
            );
        }
    }

    const generateProductImages = async (imageUrl, product) => {
        trackEvent('Instant BG Action');
        const numberOfImages = product.imageNumber
        const promptText = product.promptText
        const config = {
            headers: {
                useruid: user.uid,
                authorization: `Bearer ${user.accessToken} `
            }
        }

        const cleanedPromptText = promptText.replace(/[^\w\s.,()+]/g, "")
        const finalPrompt = `${product.name} ${cleanedPromptText}`

        const body = {
            imageNumber: numberOfImages,
            prompt: finalPrompt,
            imagePath: imageUrl,
            stripeCustomerID: stripeCustomerID,
        }

        await axios.post(
            generateProductImageURL,
            body,
            config
        ).then((response) => {
            setIsLoading(false);
            setImages(response.data.imageList);
            trackEvent('Instant BG Success');
        }).catch((error) => {
            console.log(error)
            setIsLoading(false);
            trackEvent('Instant BG Action');
        }
        );
    }

    const handleImageClick = (index) => {
        const newImages = [...images];
        [newImages[0], newImages[index]] = [newImages[index], newImages[0]];
        setImages(newImages);
    };

    function removeBase64Prefix(base64Data) {
        const parts = base64Data.split(",");
        return parts.length > 1 ? parts[1] : parts[0];
    }

    const onSelectedNumberOfImagesValuesChange = (newValue) => {
        if (newValue > availableImageCredits) {
            setModalInfo({
                ...modalInfo,
                subTitle: `You need at least ${newValue} credits to generate ${newValue} images. Please add more credits.`
            })
            setIsModalOpen(true)
            return true;
        }
    };

    const downloadImage = async (input, toolSource) => {
        trackEvent('Download Image', { toolSource: toolSource });
        let blob;

        // Check if the input is a URL or base64
        if (input.startsWith('http') || input.startsWith('https')) {
            const response = await axios.get(input, {
                responseType: 'blob',
            });
            blob = new Blob([response.data], { type: 'image/png' });
        } else if (input.startsWith('data:image')) {
            const base64Data = input.split(',')[1];
            const byteCharacters = atob(base64Data);
            const byteNumbers = Array.prototype.map.call(byteCharacters, char => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            blob = new Blob([byteArray], { type: 'image/png' });
        }

        if (blob) {
            saveAs(blob, 'wordlad-image-result.jpg');
        } else {
            console.error('The provided input is neither a URL nor a valid base64 encoded image.');
        }
    };


    const goToPricing = (e) => {
        e.preventDefault();
        navigate(ROUTES.PRICING)
        trackEvent('Add Credits Button Clicked');
    }

    const goToShareFeedback = (e) => {
        e.preventDefault();
        navigate(ROUTES.FEEDBACK)
        trackEvent('Share Feedback Button Clicked');
    }

    const onBase64ImagesResult = (imageList) => {
        setImages(imageList)
    }

    return <div className="flex flex-col md:flex-row font-inter bg-gray-50 overflow-hidden">
        {isModalOpen && <Modal
            title={modalInfo.title}
            subtitle={modalInfo.subTitle}
            isOpen={isModalOpen}
            onDismissClicked={onDismissClicked}
            onUpgradeClicked={onUpgradeClicked}
        />}

        <div className="w-full md:w-4/12 h-full text-blue-900 shadow-inner">
            <div className='p-2 flex flex-col h-screen'>
                {currentTool === "default" ? (<div>
                    <div className="items-center p-4">
                        <span className="font-semibold text-lg ml-2">What do you want to do today?</span>
                        <div className='mt-4 mx-4 ml-2'>
                            <div className='flex flex-col flex-wrap my-2'>
                                {editorTools.map((section, idx) => {
                                    return <div key={idx} className=''>
                                        <p className='font-bold border-b border-blue-900'>{section.title}</p>
                                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto my-4'>
                                            {section.tools.map((tool, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setCurrentTool(tool.type);
                                                            trackEvent('Photos Tool Clicked', { label: tool.type });
                                                        }}
                                                        className='flex flex-col text-center mx-2 cursor-pointer'
                                                    >
                                                        <div className='relative group hover:scale-110'>
                                                            <img
                                                                className='lg:h-20 rounded-lg border lg:my-0'
                                                                src={tool.src}
                                                                alt={tool.title}
                                                            />
                                                            {tool.isPro && (
                                                                <div className='absolute bottom-0 left-0 p-2 opacity-0 group-hover:opacity-100 transition-opacity'>
                                                                    <AiOutlineCrown className='text-yellow-500' />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <p className='text-xs mt-2'>{tool.title}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                    </div>
                                })
                                }

                            </div>
                        </div>
                    </div>
                </div>) :
                    ((() => {
                        switch (currentTool) {
                            case "instant-bg":
                                return <InstantBGEditor
                                    goBack={goBack}
                                    generateImages={generateImages}
                                    isLoading={isLoading}
                                    handleProductAssetSelected={handleFileSelected}
                                    handleDemoFileSelected={handleDemoFileSelected}
                                    availableImageCredits={availableImageCredits}
                                    onSelectedNumberOfImagesValuesChange={onSelectedNumberOfImagesValuesChange} />
                            case "color-bg":
                                return <ColorBGEditor
                                    goBack={goBack}
                                    handleProductAssetSelected={handleFileSelected}
                                    handleDemoFileSelected={handleDemoFileSelected}
                                    availableImageCredits={availableImageCredits}
                                    onBase64ImagesResult={onBase64ImagesResult}
                                    user={user}
                                    onShouldOpenCreditsModal={() => {
                                        setModalInfo({
                                            ...modalInfo,
                                            subTitle: "You do not have any available credits. Background color change requires at least 1 credit in your account."
                                        })
                                        setIsModalOpen(true)
                                    }}
                                />
                            case "remove-bg":
                                return <RemoveBGEditor
                                    goBack={goBack}
                                    isLoading={isLoading}
                                    handleProductAssetSelected={handleFileSelected}
                                    handleDemoFileSelected={handleDemoFileSelected}
                                    onBase64ImagesResult={onBase64ImagesResult}
                                />
                            default:
                                return null;
                        }
                    })()
                    )}
            </div>
        </div>
        <div className="w-full md:h-screen flex-grow flex flex-col bg-white">
            <div className=''>
                <div className='flex justify-center md:justify-end my-2 md:mx-2'>
                    <button onClick={goToShareFeedback} className="flex items-center border border-blue-900 hover:bg-gradient-to-r hover:from-blue-800 hover:to-blue-900 hover:text-white py-1 px-3 mx-4 rounded-full text-blue-900 text-sm">
                        <BsChatDots className="mx-1 my-1" />
                        Share Feedback
                    </button>
                    <button onClick={goToPricing} className="flex items-center bg-gradient-to-r from-crayola-600 to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 py-1 px-3 rounded-full text-white text-sm">
                        <BsStar className="mx-1 my-1" />
                        Add Credits
                    </button>
                </div>
            </div>

            {currentTool === "default" ? (<></>) :
                ((() => {
                    switch (currentTool) {
                        case "instant-bg":
                            return <InstantBGEditorPreview
                                productAsset={productAsset}
                                images={images}
                                downloadImage={downloadImage}
                                handleImageClick={handleImageClick} />
                        case "color-bg":
                            return <ColorBGPreview
                                productAsset={productAsset}
                                images={images}
                                handleImageClick={handleImageClick}
                                downloadImage={downloadImage} />
                        case "remove-bg":
                            return <RemoveBGPreview
                                productAsset={productAsset}
                                images={images}
                                handleImageClick={handleImageClick}
                                downloadImage={downloadImage} />
                        default:
                            return null;
                    }
                })()
                )}
        </div>
    </div >
}

export default Editor;