import React, { useEffect, useState } from "react";
import withRouter from "../../utils/index"
import { useSession } from "../Session";
import { Formik } from "formik";
import {
    auth,
    signInWithEmailAndPassword,
    googleAuthProvider,
    signInWithPopup,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    signInWithEmailLink
} from "../../api/Firebase";
import * as ROUTES from "../../constants/routes";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ErrorSolid } from "../Errors/error.solid";
import { LargeLogo } from "../Logo/index";
import ImageGrid from "./ImageGrid";

const Login = () => {
    const emailIsRequired = "Email is required";
    const passwordIsRequired = "Password is required";
    const invalidEmailPassword = "Invalid email address";
    const user = useSession();
    const [serverError, setServerError] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()
    const [from, setFrom] = useState(location.state?.from || ROUTES.HOME);
    const [isLoading, setIsLoading] = useState(false)
    // const [message, setMessage] = useState('Email sent to ${email}. Please check for a sign-in link.');
    const [message, setMessage] = useState('');

    if (user) {
        return <Navigate to={ROUTES.HOME} />;
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
            email = window.prompt('Please provide your email for confirmation');
        }

        signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {

                window.localStorage.removeItem('emailForSignIn');

            })
            .catch((error) => {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
            });
    }

    const signInWithGoogle = (e) => {
        e.preventDefault()
        signInWithPopup(auth, googleAuthProvider)
            .then((result) => {
                if (location.state.from === "/pricing") {
                    navigate(ROUTES.PRICING)
                }
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
            });
    }

    const sendSignInLink = async (event) => {
        event.preventDefault();


    };


    return (
        <div className="flex flex-col lg:flex-row h-screen font-inter">
            <div className="w-full lg:w-5/12 h-full bg-crayola-50">
                <div className="flex flex-col justify-center items-center mt-28 ">
                    <LargeLogo />
                    <p className="mx-10 mt-4 mb-10 md:text-xl text-blue-900 text-center font-semibold">
                        Create eye-catching eCommerce product photos, anytime, anywhere.
                    </p>
                    <ImageGrid />
                </div>
            </div>
            <div className="w-full lg:w-7/12 bg-white shadow-md rounded">
                <div className="md:mx-32 mt-20">
                    {serverError === true && (
                        <ErrorSolid message={"Error! Wrong email or password."} />
                    )}
                    <div className="flex items-center justify-between px-8 pt-6 pb-8 mb-4 mt-10">
                        <p className="text-xl text-blue-900 inline-block font-heading font-bold">
                            Sign in
                        </p>
                    </div>
                    <div className="px-8 w-full">
                        <button onClick={signInWithGoogle}
                            class="w-full justify-center px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                            <img class="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                            <span>Sign in with Google</span>
                        </button>
                    </div>
                    <div className="px-8 my-10 flex w-full items-center">
                        <div className="w-full mr-2 border-b border-gray-100"></div>
                        OR
                        <div className="w-full ml-2  border-b border-gray-100"></div>
                    </div>
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = emailIsRequired;
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email
                                )
                            ) {
                                errors.email = invalidEmailPassword;
                            }

                            // if (!values.password) {
                            //     errors.password = passwordIsRequired;
                            // }

                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setIsLoading(true)
                            const email = values.email
                            const actionCodeSettings = {
                                url: window.location.href, // This is where the user will be redirected after email verification. Adjust accordingly.
                                handleCodeInApp: true,
                            };

                            try {
                                sendSignInLinkToEmail(auth, email, actionCodeSettings);
                                setMessage(`Email sent to ${email}. Please check for a sign-in link.`);
                                setIsLoading(false)
                                window.localStorage.setItem('emailForSignIn', email);
                            } catch (err) {
                                // setError(err.message);
                                setServerError(true);
                                setSubmitting(false);
                                setIsLoading(false)
                            }
                        }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="px-8 pb-8 mb-4">
                                {message ? <p className="text-blue-900 text-lg">{message}</p> : <div>
                                    <div className="mb-4">
                                        <p className="text-red-500 float-right font-body">
                                            {errors.email &&
                                                touched.email &&
                                                errors.email}
                                        </p>
                                        <label
                                            className="block text-blue-900 text-lg font-bold mb-2 font-body"
                                            htmlFor="email">
                                            Sign in with Email
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            name="email"
                                            className="appearance-none border rounded w-full py-2 px-3  text-blue-900 mb-3 leading-tight focus:outline focus:outline-1 focus:outline-blue-800 focus:shadow-outline font-body"
                                            id="email"
                                            type="text"
                                            placeholder="Email"
                                        />
                                    </div>

                                    {/* <div className="mb-6">
                                    <p className="text-red-500 float-right font-body">
                                        {errors.password &&
                                            touched.password &&
                                            errors.password}
                                    </p>
                                    <label
                                        className="block text-blue-900 text-lg font-bold mb-2 font-body"
                                        htmlFor="password">
                                        Password
                                    </label>
                                    <input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        name="password"
                                        className="appearance-none border rounded w-full py-2 px-3  text-blue-900 mb-3 leading-tight focus:outline focus:outline-1 focus:outline-blue-800 focus:shadow-outline font-body"
                                        id="password"
                                        type="password"
                                        placeholder="Enter your password"
                                    />
                                </div> */}

                                    <div className="flex  items-center justify-between">
                                        <button type="submit" className=' w-full bg-gradient-to-r from-crayola-700 to to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-2 px-4' disabled={isSubmitting}>
                                            {isSubmitting ? <div className='flex justify-center items-center'>
                                                <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                <span className="font-medium">Loading... </span>
                                            </div> : 'Send sign in link'}
                                        </button>
                                        {/* <a
                                        className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 font-body"
                                        href="#">
                                        Forgot Password?
                                    </a> */}
                                    </div>
                                </div>}

                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Login);
