export const LANDING = "/";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const SELECT_TYPE_EDITOR = "/select-type"
// export const EDITOR = "/editor";
export const PRICING = "/pricing"
export const ORDER_SUCCESS = "/order-success"
export const ORDER_SUCCESS_PAY_AS_YOU_GO = "/order-success-pay-as-you-go"
export const ORDER_CANCELLED = "/order-cancelled"
export const NEW_EDITOR = "/editor"
export const FEEDBACK = "/share-feedback"