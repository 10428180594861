import React, { useState, useEffect } from 'react';
import { AiOutlineEdit, AiOutlineCheck } from 'react-icons/ai';

const EditableProductName = ({ text, initialName, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [tempName, setTempName] = useState(initialName);

    useEffect(() => {
        setTempName(initialName);
    }, [initialName]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCheckClick = () => {
        onSave(tempName);
        setIsEditing(false);
    };

    return (
        <p className='my-4 text-blue-900 flex items-center '>
            Product name:
            {isEditing ?
                <input
                    value={tempName}
                    onChange={(e) => setTempName(e.target.value)}
                    className='font-semibold bg-blue-900 text-white rounded-lg px-2 py-1 ml-2'
                    disabled={!isEditing}
                /> :
                <span className='font-semibold bg-blue-900 text-white rounded-lg px-2 py-1 ml-2'>
                    {tempName}
                </span>
            }
            <button className='ml-2' onClick={isEditing ? handleCheckClick : handleEditClick}>
                {isEditing ? <AiOutlineCheck size={20} /> : <AiOutlineEdit size={20} />}
            </button>
        </p>
    );
};

export default EditableProductName;
