import React, { useEffect, useRef, useState } from 'react';

function getCaret(el) {
    let caretAt = 0;
    const sel = window.getSelection();

    if (sel.rangeCount == 0) { return caretAt; }

    const range = sel.getRangeAt(0);
    const preRange = range.cloneRange();
    preRange.selectNodeContents(el);
    preRange.setEnd(range.endContainer, range.endOffset);
    caretAt = preRange.toString().length;

    return caretAt;
}

function setCaret(el, offset) {
    if (el && el.childNodes[0] && el.childNodes[0].nodeType === Node.TEXT_NODE) {
        let sel = window.getSelection();
        let range = document.createRange();

        range.setStart(el.childNodes[0], offset);
        range.collapse(true);

        sel.removeAllRanges();
        sel.addRange(range);
    } else {
        console.warn('Could not set the caret position. Invalid node or node type.');
    }
}

const InputWithTitle = ({ title, productName, content, onValueChange }) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null)
    const caretPos = useRef();

    const handleChange = event => {
        setInputValue(event.target.textContent);
        onValueChange(event.target.textContent);
    }

    useEffect(() => {
        setInputValue(content);
        if (inputRef.current && inputRef.current.childNodes[0]) {
            inputRef.current.childNodes[0].nodeValue = content;
        }
    }, [content]);

    useEffect(() => {
        setCaret(inputRef.current, caretPos.current);
        inputRef.current.focus();
    }, [inputValue]);

    return (
        <div className="flex flex-col mt-4">
            <p className="text-blue-900 font-semibold text-sm ml-2">{title}</p>
            <div
                contentEditable
                ref={inputRef}
                className='before:content-[attr(before)] before:mr-1 before:bg-blue-900 before:text-white before:rounded-lg before:px-2 w-full h-28 border border-gray-200 p-2 mt-2 rounded-md mr-0 md:mr-32 overflow-auto'
                before={productName}
                onInput={(e) => {
                    caretPos.current = getCaret(inputRef.current);
                    handleChange(e);
                }}>
                {inputValue}
            </div>
        </div>
    );
};

export default InputWithTitle;
