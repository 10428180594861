const ButtonGroup = ({ title, buttons, isMultiSelect, direction, onSelectedValuesChange }) => {

    return (
        <div className='w-full  my-4'>
            <h1 className='ml-2 font-semibold text-sm'>{title}</h1>
            <div className='flex-wrap' style={{ display: 'flex', flexDirection: direction === 'horizontal' ? 'row' : 'column' }}>
                {buttons.map((button, index) => (
                    <button
                        className={`border ${button.isSelected ? 'border-crayola-700 border-1' : 'border-blue-200'} p-2 m-2 rounded-md text-sm w-10 h-10`}
                        key={index}
                        type={isMultiSelect ? "checkbox" : "radio"}
                        name={button.id}
                        value={button.value}
                        onClick={() => onSelectedValuesChange(button.id)}
                        checked={button.isSelected}
                    >
                        {button.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ButtonGroup;