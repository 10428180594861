import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip'
import ImageUploadComponent from '../../components/ImageUploadComponent';
import { AiOutlineCrown, AiOutlineDown, AiOutlineLeft, AiOutlineRobot, AiOutlineUp } from 'react-icons/ai';
import axios from 'axios';
import candleBackgroundImage from "../../../../assets/tools/remove-background/candle-background.png"
import cleanserBackgroundImage from "../../../../assets/tools/remove-background/cleanser-background.png"
import serumBackgroundImage from "../../../../assets/tools/remove-background/serum-background.png"
import shoesBackgroundImage from "../../../../assets/tools/remove-background/shoes-background.png"
import skincareBackgroundImage from "../../../../assets/tools/remove-background/skincare-background.png"
import { trackEvent } from '../../../../api/mixpanel';

const demoProducts = [
    { "src": candleBackgroundImage },
    { "src": cleanserBackgroundImage },
    { "src": serumBackgroundImage },
    { "src": shoesBackgroundImage },
    { "src": skincareBackgroundImage }
]

const colors = [
    {
        "category": "Default",
        "type": "solid",
        "colorList": [{
            "name": "white",
            "hex": "#FFFFFF"
        },
        {
            "name": "black",
            "hex": "#000000"
        },
        {
            "name": "pink",
            "hex": "#FFC0CB"
        },
        {
            "name": "blue",
            "hex": "#0000FF"
        },
        {
            "name": "red",
            "hex": "#FF0000"
        },
        {
            "name": "yellow",
            "hex": "#FFFF00"
        },
        {
            "name": "gray",
            "hex": "#808080"
        },
        {
            "name": "brown",
            "hex": "#A52A2A"
        },
        {
            "name": "beige",
            "hex": "#F5F5DC"
        },
        {
            "name": "green",
            "hex": "#008000"
        },
        {
            "name": "neon blue",
            "hex": "#1E90FF"
        },
        {
            "name": "orange",
            "hex": "#FFA500"
        },
        {
            "name": "purple",
            "hex": "#800080"
        },
        {
            "name": "cyan",
            "hex": "#00FFFF"
        },
        {
            "name": "teal",
            "hex": "#008080"
        },
        {
            "name": "turquoise",
            "hex": "#40E0D0"
        },
        {
            "name": "peach",
            "hex": "#FFDAB9"
        },
        {
            "name": "silver",
            "hex": "#C0C0C0"
        },
        {
            "name": "maroon",
            "hex": "#800000"
        },
        {
            "name": "cream",
            "hex": "#FFFDD0"
        }
        ]
    },
    {
        "category": "Red",
        "type": "solid",
        "colorList": [{
            "name": "firebrick",
            "hex": "#B22222"
        },
        {
            "name": "darkred",
            "hex": "#8B0000"
        },
        {
            "name": "red",
            "hex": "#FF0000"
        },
        {
            "name": "lightcoral",
            "hex": "#F08080"
        },
        {
            "name": "indianred",
            "hex": "#CD5C5C"
        }
        ]
    },
    {
        "category": "Blue",
        "type": "solid",
        "colorList": [{
            "name": "navy",
            "hex": "#001F3F"
        },
        {
            "name": "darkblue",
            "hex": "#00008B"
        },
        {
            "name": "mediumblue",
            "hex": "#0000CD"
        },
        {
            "name": "blue",
            "hex": "#0000FF"
        },
        {
            "name": "royalblue",
            "hex": "#4169E1"
        },
        {
            "name": "lightblue",
            "hex": "#ADD8E6"
        },
        {
            "name": "skyblue",
            "hex": "#87CEEB"
        }
        ]
    },
    {
        "category": "Blue",
        "type": "solid",
        "colorList": [{
            "name": "navy",
            "hex": "#001F3F"
        },
        {
            "name": "darkblue",
            "hex": "#00008B"
        },
        {
            "name": "mediumblue",
            "hex": "#0000CD"
        },
        {
            "name": "blue",
            "hex": "#0000FF"
        },
        {
            "name": "royalblue",
            "hex": "#4169E1"
        },
        {
            "name": "lightblue",
            "hex": "#ADD8E6"
        },
        {
            "name": "skyblue",
            "hex": "#87CEEB"
        }
        ]
    },
    {
        "category": "Green",
        "type": "solid",
        "colorList": [{
            "name": "darkgreen",
            "hex": "#006400"
        },
        {
            "name": "green",
            "hex": "#008000"
        },
        {
            "name": "forestgreen",
            "hex": "#228B22"
        },
        {
            "name": "limegreen",
            "hex": "#32CD32"
        },
        {
            "name": "lime",
            "hex": "#00FF00"
        },
        {
            "name": "lightgreen",
            "hex": "#90EE90"
        }
        ]
    },
    {
        "category": "Yellow",
        "type": "solid",
        "colorList": [{
            "name": "gold",
            "hex": "#FFD700"
        },
        {
            "name": "yellow",
            "hex": "#FFFF00"
        },
        {
            "name": "lightyellow",
            "hex": "#FFFFE0"
        },
        {
            "name": "palegoldenrod",
            "hex": "#EEE8AA"
        }
        ]
    },
    {
        "category": "Purple",
        "type": "solid",
        "colorList": [{
            "name": "indigo",
            "hex": "#4B0082"
        },
        {
            "name": "mediumslateblue",
            "hex": "#7B68EE"
        },
        {
            "name": "purple",
            "hex": "#800080"
        },
        {
            "name": "mediumorchid",
            "hex": "#BA55D3"
        },
        {
            "name": "violet",
            "hex": "#EE82EE"
        }
        ]
    }
]

const changeBackgroundColorURL = `${process.env.REACT_APP_BASE_URL}/change-background-color/`

export const ColorBGEditor = ({
    user,
    handleProductAssetSelected,
    handleDemoFileSelected,
    availableImageCredits,
    onBase64ImagesResult,
    goBack,
    onShouldOpenCreditsModal
}) => {
    const initialStepsState = [
        { position: 0, completed: false, enabled: true, isSelected: true },
        { position: 1, completed: false, enabled: false, isSelected: false },
    ];

    const [steps, setSteps] = useState(initialStepsState);
    const [areProductImageAndColorEmpty, setAreProductImageAndColorEmpty] = useState(false)
    const [selectedColor, setSelectedColor] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [product, setProduct] = useState({
        promptText: "",
        imageBase64: null,
        demoImageUrl: null,
        isDemoProduct: false,
        imageNumber: 1,
        name: "",
        fileName: ""
    });

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredColors, setFilteredColors] = useState(colors);

    function isValidHexColor(hex) {
        return /^#([0-9A-F]{3}){1,2}$/i.test(hex);
    }

    useEffect(() => {
        if (searchTerm.length >= 2) {
            const filteredColors = colors.filter(category =>
                category.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
                category.colorList.some(color => color.hex.toLowerCase().includes(searchTerm.toLowerCase()))
            );

            if (filteredColors.length === 0 && isValidHexColor(searchTerm)) {
                const customColorCategory = {
                    category: "Custom",
                    type: "solid",
                    colorList: [{ name: "Custom Color", hex: searchTerm }]
                };

                setSelectedColor({
                    ...selectedColor,
                    color: customColorCategory.colorList,
                    type: customColorCategory.type
                });

                filteredColors.push(customColorCategory);
            }

            setFilteredColors(filteredColors);
        } else {
            setFilteredColors(colors);
        }
    }, [searchTerm]);

    const completeFirstStep = () => {
        setStepCompleted(0)
        setStepEnabled(1)
        setStepSelected(1)
    }

    const handleFileSelected = (base64, fileName) => {
        if (base64 !== null) {
            handleProductAssetSelected(base64)
            setProduct({
                ...product,
                imageBase64: base64,
                fileName: fileName,
                isDemoProduct: false,
                demoImageUrl: null
            });
        } else {
            handleProductAssetSelected(base64)
        }
        completeFirstStep()
    };

    const changeBackgroundColor = async () => {
        if (availableImageCredits === 0) {
            onShouldOpenCreditsModal(true)
            return;
        }

        let imageBase64;
        if ((product.imageBase64 === null && product.demoImageUrl === null)) {
            setAreProductImageAndColorEmpty(true);
            return;
        } else {
            setAreProductImageAndColorEmpty(false);
        }

        setIsLoading(true);

        let prefix = '';

        if (product.isDemoProduct) {
            const imageResult = await fetchImageAndConvertToBase64(product.demoImageUrl);
            const parts = imageResult.split(",");
            prefix = parts[0];
            imageBase64 = parts.length > 1 ? parts[1] : parts[0];
        } else {
            const parts = product.imageBase64.split(",");
            prefix = parts[0];
            imageBase64 = parts.length > 1 ? parts[1] : parts[0];
        }

        const config = {
            headers: {
                useruid: user.uid,
                authorization: `Bearer ${user.accessToken} `
            }
        }

        trackEvent('Color BG Action', { selectedColor: selectedColor });

        const body = {
            base64Image: imageBase64,
            background: {
                type: selectedColor.type,
                color: selectedColor.color.hex
            }
        };

        await axios.post(
            changeBackgroundColorURL,
            body,
            config
        ).then((response) => {
            const imageList = [`${prefix},${response.data.image}`];
            onBase64ImagesResult(imageList);
            setIsLoading(false);
            trackEvent('Color BG Success');
        }).catch((error) => {
            setIsLoading(false);
            trackEvent('Color BG Failure');
        });
    };

    const fetchImageAndConvertToBase64 = async (imgPath) => {
        const response = await fetch(imgPath);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    const setStepEnabled = (stepIndex) => {
        steps[stepIndex].enabled = true;
        setSteps(steps);
    }

    const setStepCompleted = (stepIndex) => {
        steps[stepIndex].completed = true;
        setSteps(steps);
    }

    const setStepSelected = (stepIndex) => {
        const newSteps = steps.map(step => ({ ...step, isSelected: false }));
        newSteps[stepIndex].isSelected = true;
        setSteps(newSteps);
    };

    return (
        <div className="flex flex-col h-full">
            <div className='flex border-b pb-2'>
                <div onClick={goBack} className='flex items-center cursor-pointer h-8 ml-2 hover:text-blue-950'>
                    <AiOutlineLeft className='text-blue-900' />
                    <p className="ml-1 text-blue-900 font-semibold text-sm">Color Background</p>
                </div>
                <div
                    className='flex items-center ml-auto text-xs font-semibold text-white bg-blue-900 rounded-full px-2'
                    data-tooltip-content='PRO feature (1 credit per image)'
                    data-tooltip-id='pro-tooltip'
                >
                    <AiOutlineCrown size={16} className='mr-1' />
                    PRO
                </div>
                <Tooltip className='z-10' id='pro-tooltip' place='top' effect='solid' />
            </div>
            {/* Step 1 */}
            <div className="flex-grow">
                <div
                    className={`mt-2 p-4 border-b-2 border-gray-50 ${steps[0].enabled ? '' : 'bg-gray-200 opacity-50 '} `}
                    onClick={(e) => {
                        if (steps[0].enabled) {
                            setStepSelected(0)
                        }
                    }}
                >
                    <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center">
                            <span className="rounded-full bg-blue-500 text-white w-6 h-6 flex items-center justify-center">1</span>
                            <span className="font-semibold ml-2">Product Asset Image</span>
                        </div>
                        <div>{steps[0].isSelected ? <AiOutlineUp /> : <AiOutlineDown />}</div>
                    </div>
                    {steps[0].isSelected && <div>
                        <ImageUploadComponent
                            fileName={product.fileName}
                            onFileDelete={(e) => {
                                e.stopPropagation()
                                setProduct({
                                    ...product,
                                    fileName: null,
                                    imageBase64: null,
                                    name: null,
                                    promptText: "",
                                    imageNumber: 1,
                                    isDemoProduct: false,
                                    demoImageUrl: null
                                })
                                handleProductAssetSelected(null)
                                completeFirstStep()
                            }
                            }
                            onFileSelected={handleFileSelected} />
                        <div className='text-center my-2'>
                            <p className='text-xs'>No photo? Try one of these:</p>
                            <div className='flex justify-center my-2'>
                                {demoProducts.map((photo, idx) => {
                                    return <img
                                        className='border-2 hover:opacity-70 rounded-lg mx-1 w-10 h-10 cursor-pointer'
                                        key={idx}
                                        src={photo.src}
                                        alt={photo.name}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setProduct({
                                                ...product,
                                                name: photo.name,
                                                imageBase64: null,
                                                fileName: "",
                                                demoImageUrl: photo.src,
                                                isDemoProduct: true
                                            })
                                            handleDemoFileSelected(photo.src)
                                            completeFirstStep()
                                        }}
                                    />
                                })}
                            </div>
                        </div>
                    </div>}
                </div>
                {/* Step 2 */}
                <div
                    className={`flex flex-col p-4 border-b-2 border-gray-50 ${steps[1].enabled ? '' : 'bg-gray-200 opacity-50'}`}
                    onClick={() => {
                        if (steps[1].enabled) {
                            setStepSelected(1)
                        }
                    }}
                >
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <span className="rounded-full bg-blue-500 text-white w-6 h-6 flex items-center justify-center">2</span>
                            <span className="font-semibold ml-2">Select color</span>
                        </div>
                        <div>{steps[1].isSelected ? <AiOutlineUp /> : <AiOutlineDown />}</div>
                    </div>
                    {steps[1].isSelected && <div className='flex flex-col flex-grow ml-2'>
                        {selectedColor ? <div
                            className={`rounded-lg w-10 h-10 inline-block ${selectedColor.color.hex} border mt-2`}
                            style={{
                                backgroundColor: selectedColor.color.hex,
                            }}
                        ></div> : <div
                            className="rounded-lg w-10 h-10 inline-block bg-checkerboard bg-cover mt-2"
                        ></div>}
                        <div className='my-2 border' />
                        <input
                            placeholder='Enter a color name or try hex #FF2822'
                            className='h-10 border rounded-lg placeholder:text-sm placeholder:text-gray-400 px-2'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className='overflow-y-auto no-scrollbar h-80'>
                            {(searchTerm.length >= 2 ? filteredColors : colors.filter(cat => cat.category === "Default")).map((category, idx) => {
                                return (
                                    <div key={idx} className='my-2 '>
                                        <p className='text-blue-900 text-sm font-semibold'>{category.category}</p>
                                        <div className='grid grid-cols-5'>
                                            {category.colorList.map((color, idx) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setSelectedColor({
                                                                ...selectedColor,
                                                                color: color,
                                                                type: category.type
                                                            });
                                                        }}
                                                        className="relative group cursor-pointer my-2 mx-2"
                                                        key={idx}
                                                    >
                                                        <div
                                                            className={`rounded-lg w-10 h-10 inline-block hover:scale-105 border`}
                                                            style={{
                                                                backgroundColor: color.hex,
                                                            }}
                                                            data-tooltip-content={`${color.name} ${color.hex}`}
                                                            data-tooltip-id='pro-tooltip-2'
                                                            data-tooltip-place='bottom'

                                                        ></div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className="shadow-lg mb-2">
                <button onClick={(e) => changeBackgroundColor(e)} className='w-full flex justify-center bg-gradient-to-r border border-crayola-800 from-crayola-700 to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-1' enabled={isLoading || areProductImageAndColorEmpty}>
                    {isLoading ?
                        <div className='flex justify-center items-center'>
                            <svg className="h-8 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span className="font-medium ml-2">Creating...please wait </span>
                        </div>
                        :
                        <div className="flex items-center font-medium"><AiOutlineRobot
                            className='h-8 w-5 mr-2'
                        />Change background</div>}
                </button>

            </div>
            {<span className='text-center text-xs'>Available Image Credits: {availableImageCredits}</span>}
        </div >
    );
};
