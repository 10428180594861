import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpandableSection from '../ExpandableSection';
import { useAuth } from '../Session';
import HeaderHeroNavigation from '../TopNavigation/header-hero';
import { SIGN_IN, HOME } from '../../constants/routes';
import { usePremiumUser } from '../User/premiumUser';
import FreePlan from './FreePlan';
import PayAsYouGoPlan from './PayAsYouGoPlan';
import { trackEvent } from '../../api/mixpanel';

const frequentlyAskedQuestions = [
    {
        "title": "What does 1 credit get me in the Pay As You Go model?",
        "description": "Each time you use a PRO feature to generate an image, we deduct one credit from your account. For instance, if your account has 20 credits, you have the ability to change the background of up to 20 images using these credits."
    },
    {
        "title": "Do credits expire in the Pay As You Go plan?",
        "description": "No, credits from the Pay As You Go model do not expire. You can use them whenever you like."
    },
    {
        "title": "What if I'm not satisfied with the generated product images?",
        "description": "We recognize that not every image result will meet your specific needs or expectations. This is why we offer generous credit plans in our Pay As You Go model, where one credit equals one image result. The aim of these plans is to give you ample room to generate multiple images until you find the ones that are just right for you. Even if our system uses advanced AI the results might not always be perfect. That's why we've priced our plans to allow for this variability—you can keep generating images without worrying about running out of credits too quickly. If you're still not satisfied after using multiple credits, we welcome you to reach out to our support team. Your feedback is invaluable in helping us improve our service."
    },
    {
        "title": "Is there a limit to how many credits I can purchase at once?",
        "description": "You can purchase up to 49000 at a promotional price, but if you need more than that feel free to contact us."
    },
    {
        "title": "Is there a refund policy for the Pay As You Go credits?",
        "description": "Yes, if you haven't used your purchased credits and feel unsatisfied with our service, we offer a full refund."
    }
    ,
    {
        "title": "How quickly are the credits available after purchase?",
        "description": "The credits are added to your account immediately after the purchase is successful, allowing you to create images right away."
    }
    ,
    {
        "title": "Is my payment information secure for Pay As You Go transactions?",
        "description": "Absolutely. All our transactions are done via Stripe."
    }
]

const images = [
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fskincare-result-5.jpeg?alt=media&token=e791182b-6570-49d3-b1d1-f4d1bc0a852a' },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fperfume-bottle-result.jpeg?alt=media&token=2a355291-c7ec-4bad-99c4-65f7c65e3c40', width: 1080, height: 800 },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fbody-lotion-result-2.jpeg?alt=media&token=0ec9737e-141f-455a-952e-ff51d625c2a9' },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fwine-bottle-result.jpeg?alt=media&token=7a497be8-6f0f-4d17-90b7-54f13231a39d' },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fbath-salts-result.jpeg?alt=media&token=095f104c-4207-4e24-88a1-35c6727a466d', width: 1080, height: 1620 },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fcandle-2-result-1.jpeg?alt=media&token=d16c67cc-870e-42c3-98b6-562fd6ea775a', width: 1080, height: 720 },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fcleanser-result-10.jpeg?alt=media&token=7a3bf6d1-9723-4eb2-b2a7-be8f63d7dc09', width: 1080, height: 721 },

    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fface-cream-result.jpeg?alt=media&token=139994cb-486a-48c7-a0da-f67fda911a05' },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fmug-result-2.jpeg?alt=media&token=960d179f-157c-41c9-8343-891e85372fe8' },

    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fchair-result-2.jpeg?alt=media&token=9019cef4-1b04-4a97-b785-d99925cc3318' },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fcbd-result-7.jpeg?alt=media&token=e47d3524-2913-4a7e-95e5-389426eb12fc' },
    { "src": 'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Frunning-shoes-result.jpeg?alt=media&token=fb797e8b-a64c-4fcd-a89f-3f627fbe080e' }
]

const url = `${process.env.REACT_APP_BASE_URL}/create-payment/`

const PricingPayAsYouGo = () => {
    // const [tab, setTab] = useState('monthly');
    const premiumUser = usePremiumUser()
    const { initializing, user } = useAuth()
    const navigate = useNavigate();
    const [currentPriceId, setCurrentPriceId] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleSubscribe = (e, selectedPlan) => {
        e.preventDefault();
        trackEvent('Pay As You Go - Buy Now', { selectedPlan: selectedPlan });
        if (!user) {
            navigate(SIGN_IN, { state: { from: '/pricing' } })
        } else {
            setIsLoading(true)
            const body = {
                priceId: selectedPlan.priceId,
                email: user.email
            };

            const headers = {
                "Accept": "text/html; charset=UTF-8",
                useruid: user.uid,
                authorization: `Bearer ${user.accessToken}`
            }

            axios.post(url, body, { headers: headers })
                .then(res => {
                    setIsLoading(false)
                    window.location.href = res.data.url
                }).catch(e => {
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        if (premiumUser && premiumUser.subscription && premiumUser.subscription.plan) {
            setCurrentPriceId(premiumUser.subscription.plan.id)
        }

    }, [premiumUser])

    const goToDashboard = (e) => {
        e.preventDefault();
        navigate(HOME)
    }

    return (
        <div className='font-inter'>
            <HeaderHeroNavigation heroTitle="Pricing" heroSubtitle="1 Credit = 1 Image Result" user={user} />
            <div className="container my-24 px-6 mx-auto">
                <section className="mb-32 text-blue-900">
                    {/* <div className="grid lg:grid-cols-3 gap-6 lg:gap-x-12 mt-6"> */}
                    <div className="flex flex-col lg:flex-row justify-center mt-12">
                        <FreePlan user={user} />
                        <PayAsYouGoPlan handleSubscribe={handleSubscribe} />
                    </div>
                </section>
                <div className='flex justify-center items-center'>
                    <div className='flex flex-col items-center text-blue-900 w-full lg:w-3/5'>
                        <h1 className='text-2xl font-extrabold'>Frequently Asked Questions</h1>
                        <div className="flex flex-col my-4 w-full">
                            {
                                frequentlyAskedQuestions.map((question, index) => <ExpandableSection key={index}
                                    title={question.title}
                                    description={question.description}
                                />)
                            }
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center mt-10'>
                    <div className='flex flex-col items-center text-blue-900 container'>
                        <h1 className='text-2xl font-extrabold'>Gallery</h1>
                        <h2 className='text-base font-medium my-2'>Explore product images created using our platform.</h2>
                        <div className='columns-2 md:columns-3 gap-5 container mx-auto space-y-3 pb-28 my-10'>
                            {images.map((image, idx) => {
                                return <div key={idx} className='break-inside-avoid hover:scale-105'>
                                    <img className='rounded-lg' src={image.src} />
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default PricingPayAsYouGo;

