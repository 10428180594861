import { firestoreDB } from "../../api/Firebase";
import { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from '../Session';

export const useGetAvailableCredits = () => {
    const { initializing, user } = useAuth();
    const [availableCredits, setAvailableCredits] = useState(0);

    if (initializing) {
        // setAvailableCredits(0);
    }

    useEffect(() => {
        const docRef = doc(firestoreDB, "users", user.uid);
        const docSnap = getDoc(docRef);
        docSnap.then((doc) => {
            if (doc.exists()) {
                setAvailableCredits(() => doc.data().credits)
            }
        }).catch(() => {
            setAvailableCredits(0);
        })
    }, [user])

    return availableCredits;
}
