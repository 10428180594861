import React from 'react';

const Modal = ({ title, subtitle, isOpen, onDismissClicked, onUpgradeClicked }) => {

    return (
        <div className='font-inter z-50'>
            {isOpen && (
                <div className="fixed top-0 left-0 h-full w-full flex items-center justify-center">
                    <div className="bg-white rounded-lg shadow-lg p-8 md:w-1/2">
                        <div className="flex items-center mb-4">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="text-blue-500 w-6 h-6 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>
                            <h2 className="text-lg font-medium text-gray-900">{title}</h2>
                        </div>
                        <p className="text-gray-700 mb-4">{subtitle}</p>
                        <div className="flex justify-end">
                            <button onClick={onDismissClicked} className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-4 rounded-md mr-2">Dismiss</button>
                            <button onClick={onUpgradeClicked} className="bg-gradient-to-r from-crayola-600 to to-crayola-700 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-2 px-4">Buy credits</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Modal;
