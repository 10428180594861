import React from "react";
import "./loadingIndicator.css";

const Loading = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loading;
