import React, { useState } from 'react';
import ImageUploadComponent from '../../components/ImageUploadComponent';
import { AiOutlineLeft, AiOutlineRobot } from 'react-icons/ai';
import axios from 'axios';
import candleBackgroundImage from "../../../../assets/tools/remove-background/candle-background.png"
import cleanserBackgroundImage from "../../../../assets/tools/remove-background/cleanser-background.png"
import serumBackgroundImage from "../../../../assets/tools/remove-background/serum-background.png"
import shoesBackgroundImage from "../../../../assets/tools/remove-background/shoes-background.png"
import skincareBackgroundImage from "../../../../assets/tools/remove-background/skincare-background.png"
import { trackEvent } from '../../../../api/mixpanel';

const demoProducts = [
    { "src": candleBackgroundImage },
    { "src": cleanserBackgroundImage },
    { "src": serumBackgroundImage },
    { "src": shoesBackgroundImage },
    { "src": skincareBackgroundImage }
]

const removeBackgroundURL = `${process.env.REACT_APP_BASE_URL}/remove-background/`

export const RemoveBGEditor = ({
    handleProductAssetSelected,
    handleDemoFileSelected,
    onBase64ImagesResult,
    goBack
}) => {
    const [areProductImageAndPromptEmpty, setAreProductImageAndPromptEmpty] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [product, setProduct] = useState({
        imageBase64: null,
        demoImageUrl: null,
        isDemoProduct: false,
        fileName: ""
    });

    const handleFileSelected = (base64, fileName) => {
        if (base64 !== null) {
            handleProductAssetSelected(base64)
            setProduct({
                ...product,
                imageBase64: base64,
                fileName: fileName,
                isDemoProduct: false,
                demoImageUrl: null
            });
        } else {
            handleProductAssetSelected(base64)
        }
    };

    const removeBackground = async () => {
        trackEvent('Remove BG Action');
        let imageBase64;
        if (product.imageBase64 === null && product.demoImageUrl === null) {
            setAreProductImageAndPromptEmpty(true);
            return;
        } else {
            setAreProductImageAndPromptEmpty(false);
        }

        setIsLoading(true);

        let prefix = '';

        if (product.isDemoProduct) {
            const imageResult = await fetchImageAndConvertToBase64(product.demoImageUrl);
            const parts = imageResult.split(",");
            prefix = parts[0];
            imageBase64 = parts.length > 1 ? parts[1] : parts[0];
        } else {
            const parts = product.imageBase64.split(",");
            prefix = parts[0];
            imageBase64 = parts.length > 1 ? parts[1] : parts[0];
        }

        const body = {
            image: imageBase64,
        };

        await axios.post(
            removeBackgroundURL,
            body
        ).then((response) => {
            const imageList = [`${prefix},${response.data.image}`]; // Use the local variable
            onBase64ImagesResult(imageList);
            setIsLoading(false);
            trackEvent('Remove BG Success');
        }).catch((error) => {
            setIsLoading(false);
            console.log(error);
            trackEvent('Remove BG Failure');
        });
    };


    const fetchImageAndConvertToBase64 = async (imgPath) => {
        const response = await fetch(imgPath);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    return (
        <div className="flex flex-col h-screen">

            <div onClick={goBack} className=' flex  items-center cursor-pointer h-8 ml-2 border-b hover:text-blue-950'>
                <AiOutlineLeft className='text-blue-900' />
                <p className="ml-1  text-blue-900  font-semibold text-sm">Remove Background</p>
            </div>
            {/* Step 1 */}
            <div
                className={`mt-2 p-4 border-b-2 border-gray-50 `}
            >
                <div>
                    <ImageUploadComponent
                        fileName={product.fileName}
                        onFileDelete={(e) => {
                            e.stopPropagation()
                            setProduct({
                                ...product,
                                fileName: null,
                                imageBase64: null,
                                isDemoProduct: false,
                                demoImageUrl: null
                            })
                            handleProductAssetSelected(null)
                        }
                        }
                        onFileSelected={handleFileSelected} />
                    <div className='text-center my-2'>
                        <p className='text-xs'>No photo? Try one of these:</p>
                        <div className='flex justify-center my-2'>
                            {demoProducts.map((photo, idx) => {
                                return <img
                                    className='border-2 hover:opacity-70 rounded-lg mx-1 w-10 h-10 cursor-pointer'
                                    key={idx}
                                    src={photo.src}
                                    alt={photo.name}
                                    onClick={() => {
                                        setProduct({
                                            ...product,
                                            name: photo.name,
                                            imageBase64: null,
                                            fileName: "",
                                            demoImageUrl: photo.src,
                                            isDemoProduct: true
                                        })
                                        handleDemoFileSelected(photo.src)
                                    }}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-auto shadow-lg mb-4">
                <button onClick={removeBackground} className='w-full flex justify-center bg-gradient-to-r border border-crayola-800 from-crayola-700 to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-1' enabled={isLoading || areProductImageAndPromptEmpty}>
                    {isLoading ?
                        <div className='flex justify-center items-center'>
                            <svg className="h-8 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span className="font-medium ml-2">Removing...please wait </span>
                        </div>
                        :
                        <div className="flex items-center font-medium"><AiOutlineRobot
                            className='h-8 w-5 mr-2'
                        />Remove background</div>}
                </button>

            </div>
            {/* {<span className='my-4 text-center text-xs'>Available Image Credits: {availableImageCredits}</span>} */}
        </div>
    );
};
