import TopNavigation from "../TopNavigation";
import { BsFillExclamationOctagonFill } from 'react-icons/bs'
import { useNavigate } from "react-router-dom";
import * as ROUTES from "./../../constants/routes"

function OrderCancelled() {

    const navigate = useNavigate();

    const goToPricing = (e) => {
        e.preventDefault();
        navigate(ROUTES.PRICING)
    }

    const goToDashboard = (e) => {
        e.preventDefault();
        navigate(ROUTES.HOME)
    }

    return (
        <div className='flex flex-row h-screen'>
            <TopNavigation />
            <div className="md:container mx-auto">
                <div className="flex flex-col mx-2 lg:mx-52 my-20 border border-blue-100 rounded-md p-4 text-blue-900 items-center">
                    <BsFillExclamationOctagonFill className="text-5xl text-red-700 mt-8 mb-4" />
                    <h1 className="font-bold text-2xl my-2 text-center">Payment Cancelled!</h1>
                    <div className="flex flex-col w-full md:w-56">
                        <button onClick={goToPricing} className="bg-gradient-to-r from-crayola-600 to to-crayola-700 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-2 px-4 mt-6">Try again</button>
                        {/* <button onClick={goToDashboard} className="border border-crayola-700 hover:bg-gray-100 text-blue-900 rounded-md outline-none my-6 py-2 px-4">Go to Dashboard</button> */}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default OrderCancelled;