import React from "react";

const ErrorSolid = ({ message }) => {
    return (
        <div
            className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3 font-body"
            role="alert">
            <p>{message ? message : ""}</p>
        </div>
    );
};

export { ErrorSolid };
