import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackEvent } from ".";

function PageViewTracker() {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== '/') {
            trackEvent('Page View', { pagePath: location.pathname });
        }
    }, [location]);

    return null;
}

export default PageViewTracker