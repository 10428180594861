import React, { useState } from 'react';

const initialImages = [
    'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fperfume-bottle.webp?alt=media&token=eaa8595f-5536-4534-be3b-ef3ce84d370e',
    'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fperfume-bottle-result-3.jpeg?alt=media&token=356f0a13-f2e3-47b8-a8fa-37f9f26f9d22',
    'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fperfume-bottle-result-2.jpeg?alt=media&token=6d3b3799-d76b-4e6a-bfe5-7fe7c41d4ed5',
    'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fperfume-bottle-result-4.jpeg?alt=media&token=803c14ed-2860-4981-b819-a102f3fe4ca3',
    'https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fperfume-bottle-result.jpeg?alt=media&token=2a355291-c7ec-4bad-99c4-65f7c65e3c40'
];

function ImageGrid() {
    const [images, setImages] = useState(initialImages);

    const handleImageClick = (index) => {
        if (index !== 0) {
            const newImages = [...images];
            [newImages[0], newImages[index]] = [newImages[index], newImages[0]];
            setImages(newImages);
        }
    };

    return (
        <div className="grid w-4/5 grid-cols-4 gap-4 h-full mb-14">
            {images.map((image, idx) => (
                <img
                    key={idx}
                    src={image}
                    alt={`grid ${idx}`}
                    className={`${idx === 0 ? "col-span-2 row-span-4" : "col-span-1 row-span-2"} rounded-lg cursor-pointer hover:scale-110`}
                    onClick={() => handleImageClick(idx)}
                />
            ))}
        </div>
    );
}

export default ImageGrid;


