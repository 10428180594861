import React, { useEffect } from 'react'
import Login from "./components/Login/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth, userContext, PrivateRoute } from "./components/Session";
import * as ROUTES from "./constants/routes"
import EditorNew from "./components/Editor-new"
import OrderCancelled from './components/Order/cancelled';
import OrderSuccess from './components/Order/success';
import Loading from './components/Loading/loadingIndicator';
import PricingPayAsYouGo from './components/Pricing/index-pay-as-you-go';
import OrderSuccessPayAsYouGo from './components/Order/success-pay-as-you-go';
import { identifyUser } from './api/mixpanel';
import PageViewTracker from './api/mixpanel/PageViewTracker';
import { signOut } from 'firebase/auth';
import { auth } from './api/Firebase';
import Feedback from './components/Feedback';

function App() {
  const { initializing, user } = useAuth();

  // useEffect(() => {
  //   signOut(
  //     auth
  //   )
  //     .then(result => {
  //       console.log(result);
  //     })
  //     .catch(error => {
  //       console.error(error);

  //     });
  // }, [user]);

  useEffect(() => {
    if (user) {
      identifyUser(user.uid);
    }
  }, [user]);

  if (initializing) {
    return (
      <div className="h-screen w-screen bg-gray-100">
        <Loading />
      </div>
    );
  }

  return (
    <userContext.Provider value={{ user }}>
      <Router>
        <PageViewTracker />
        <Routes>
          <Route path={ROUTES.LANDING} element={<Login />} />
          <Route path={ROUTES.SIGN_IN} element={<Login />} />
          <Route path={ROUTES.PRICING} element={<PricingPayAsYouGo />} />
          <Route path={ROUTES.HOME} element={<PrivateRoute><EditorNew /></PrivateRoute>} />
          <Route path={ROUTES.ORDER_SUCCESS} element={<PrivateRoute><OrderSuccess /></PrivateRoute>} />
          <Route path={ROUTES.ORDER_SUCCESS_PAY_AS_YOU_GO} element={<PrivateRoute><OrderSuccessPayAsYouGo /></PrivateRoute>} />
          <Route path={ROUTES.ORDER_CANCELLED} element={<PrivateRoute><OrderCancelled /></PrivateRoute>} />
          <Route path={ROUTES.NEW_EDITOR} element={<PrivateRoute><EditorNew /></PrivateRoute>} />
          <Route path={ROUTES.FEEDBACK} element={<PrivateRoute><Feedback /></PrivateRoute>} />
        </Routes>
      </Router>
    </userContext.Provider>
  );
}

export default App;
