function ImageModal({ isOpen, imageSrc, productName, prompt, onClose, onPromptSelect }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="bg-white rounded-lg shadow-xl relative animate-fadeInScale flex flex-col w-full  md:w-1/3">
                <div className="relative flex-grow">
                    <button onClick={onClose} className="absolute top-4 right-4 z-10 text-gray-900">Close</button>
                    <img src={imageSrc} alt="Enlarged" className=" object-cover max-h-[70vh] w-full rounded-t-lg" />
                </div>

                <div className="p-4 bg-gray-100 rounded-b-lg flex flex-col space-y-2">
                    <p className="break-words">{productName} {prompt}</p>
                    <button onClick={() => onPromptSelect(prompt)} className="w-full flex justify-center bg-gradient-to-r border border-crayola-800 from-crayola-700 to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-1">
                        Select prompt
                    </button>
                </div>
            </div>
        </div>
    );
}


export default ImageModal