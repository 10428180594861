// mixpanelUtil.js
import mixpanel from 'mixpanel-browser';
import { mixpanelKey, debug } from './config';

mixpanel.init(mixpanelKey, {
    debug: debug,
    track_pageview: false,
    persistence: 'localStorage',
});

const trackEvent = (eventName, properties) => {
    try {
        mixpanel.track(eventName, properties);
    } catch (error) {
        console.error('Error tracking Mixpanel event:', error);
    }
};

const identifyUser = (userId) => {
    try {
        mixpanel.identify(userId);
    } catch (error) {
        console.error('Error identifying Mixpanel user:', error);
    }
};

export { trackEvent, identifyUser };
