import { firestoreDB } from "../../api/Firebase";
import { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from '../Session';

export const useGetStripeCustomerID = () => {
    const { initializing, user } = useAuth();
    const [stripeCustomerId, setStripeCustomerId] = useState(null);

    useEffect(() => {
        const docRef = doc(firestoreDB, "users", user.uid);
        const docSnap = getDoc(docRef);
        docSnap.then((doc) => {
            if (doc.exists()) {
                setStripeCustomerId(() => doc.data().stripeCustomerID)
            }
        }).catch(() => {
            setStripeCustomerId(null);
        })
    }, [user])

    return stripeCustomerId;
}
