import React, { useRef, useState } from 'react';
import { AiOutlineUpload, AiOutlineCloseCircle } from 'react-icons/ai';

const ImageUploadComponent = ({ text, fileName, onFileDelete, onFileSelected }) => {
    const fileInputRef = useRef();

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64 = reader.result;
            onFileSelected(base64, file.name);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleDelete = (e) => {
        onFileDelete(e)
        fileInputRef.current.value = null;
    };

    return (
        <div>
            <div
                className=' cursor-pointer border-gray-200 border-2 border-dashed flex rounded-md py-4 px-4 items-center text-gray-600 text-base font-inter'
                onClick={!fileName ? handleDivClick : null}
            >
                {fileName ? (
                    <div className="flex justify-between w-full items-center">
                        {fileName}
                        <AiOutlineCloseCircle onClick={(e) => handleDelete(e)} />
                    </div>
                ) : (
                    <>
                        <AiOutlineUpload className='mx-2' />
                        Add a product image
                    </>
                )}
            </div>
            <input
                type='file'
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*"
            />
        </div>
    );
};

export default ImageUploadComponent;
