import { useState } from "react";
import { useAuth } from "../Session";
import HeaderHeroNavigation from "../TopNavigation/header-hero";
import { firestoreDB } from "../../api/Firebase";
import { addDoc, collection } from "firebase/firestore";


const Feedback = () => {
    const { initializing, user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('')

    const shareFeedback = async (e) => {
        e.preventDefault()
        if (feedback.length < 20) {
            setError("Feedback must be at least 20 characters long.");
            return;
        }
        setIsLoading(true);
        try {
            await addDoc(collection(firestoreDB, "feedback"), {
                email: user.email,
                text: feedback,
                date: new Date()
            });
            setFeedback('');
            setError('');
            setIsLoading(false);
            setSuccessMessage('Thank you for sharing your feedback.')
        } catch (e) {
            setError("An error occurred. Please try again.");
            setSuccessMessage('')
        }
    };


    return (
        <div className="font-inter">
            <HeaderHeroNavigation
                heroTitle="Tell us your opinion"
                heroSubtitle="Help us improve your experience! Your feedback, whether praise or critique, is crucial to improving our app. Share what you love, what you don't, and what you wish to see next."
                user={user}
            />
            <div className="container mt-24 mx-auto">
                <div className="md:w-1/3 mx-auto">
                    {error && <p className="text-red-500 mb-2">{error}</p>}
                    {successMessage && <p className="text-green-700 font-semibold mb-2">{successMessage}</p>}
                    <textarea
                        placeholder="Write your opinion here..."
                        className="w-full h-48 border border-gray-300 rounded-lg text-blue-900 p-2 resize-none"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                    <div className="pt-4">
                        <button
                            onClick={shareFeedback}
                            className='w-full bg-gradient-to-r from-crayola-700 to to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-2 px-4'
                            disabled={isLoading}
                        >
                            {isLoading ? <div className='flex justify-center items-center'>
                                <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <span className="font-medium">Loading... </span>
                            </div> : 'Send'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feedback;
