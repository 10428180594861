import React from "react";
import { Navigate } from "react-router-dom";
import { useSession } from "../Session";
import { LANDING } from "../../constants/routes";

export const PrivateRoute = ({ children }) => {
    const user = useSession();

    return user ? children : <Navigate to={LANDING} replace />;
};
