import React, { useState, useEffect, useContext } from "react";
import { firebaseApp, auth, onAuthStateChanged } from "../../api/Firebase";
import { PrivateRoute } from "./private_route";

export const useAuth = () => {
    const [state, setState] = useState(() => {
        const user = auth.currentUser;
        return { initializing: !user, user };
    });

    useEffect(() => {
        // listen for auth state changes
        var metadataUnsubscribe = null;
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("User changed: ", user);
            if (metadataUnsubscribe) {
                metadataUnsubscribe();
            }
            // if (state.user == null && user != null) {
            //     metadataUnsubscribe = firebaseApp
            //         .firestore()
            //         .collection("metadata")
            //         .doc(user.uid)
            //         .onSnapshot(snapshot => {
            //             console.log("onSnapshot");
            //             if (snapshot.exists) {
            //                 metadataUnsubscribe();
            //                 console.log("force update");
            //                 user.getIdToken(true);
            //             }
            //         });
            // }
            setState({ initializing: false, user });
        });

        // unsubscribe to the listener when unmounting
        return () => unsubscribe();
    }, []);

    return state;
};

export const userContext = React.createContext({
    user: null
});

export const useSession = () => {
    const { user } = useContext(userContext);
    return user;
};

export { PrivateRoute };
