import { useRef } from "react";
import { Link } from "react-router-dom";
import { HOME } from "../../constants/routes";
import CurrentPlan from "../CurrentPlan";
import { SmallLogo } from "../Logo";
import { FaHome } from "react-icons/fa"

function TopNavigation() {
    const navRef = useRef(null);
    const navContent = useRef(null);

    const toggleNav = () => {
        navContent.current.classList.toggle("hidden");
    }

    return <nav className="flex flex-col lg:w-52 items-center bg-gradient-to-r bg-gray-50 flex-wrap bg-grey-darkest font-semibold font-inter">
        <div className="flex flex-row w-full lg:flex-col items-center justify-between p-4">
            <div className="flex ">
                <Link
                    className="no-underline hover:text-white hover:no-underline"
                    to={HOME}>
                    <SmallLogo />
                </Link>
            </div>
            <div className="hidden lg:block w-full border-b border-blue-100 my-6"></div>
            <div className="lg:hidden">
                <button onClick={toggleNav} ref={navRef} id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-grey border-grey-dark hover:text-blue-900 hover:border-blue-900">
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                </button>
            </div>
        </div>

        <div className="w-full lg:flex lg:mt-2 hidden lg:pt-0 px-4 lg:px-0" ref={navContent} id="nav-content">
            <ul className="list-reset lg:flex lg:flex-col flex-1 items-center justify-start">
                <li className="w-full flex items-center cursor-pointer px-10 py-2 rounded-full text-sm hover:text-crayola-700 text-blue-900">
                    <FaHome className="mr-2" />
                    <Link
                        className="no-underline"
                        to={HOME}>
                        Home
                    </Link>
                </li>
            </ul>
        </div>
        <CurrentPlan />
    </nav>
}

export default TopNavigation