import React, { useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip'
import ImageUploadComponent from '../../components/ImageUploadComponent';
import InputWithTitle from '../../../InputTitle';
import ButtonGroup from '../../../ButtonGroup';
import { AiOutlineCrown, AiOutlineDown, AiOutlineLeft, AiOutlineRobot, AiOutlineUp } from 'react-icons/ai';
import PhotoGrid from '../../components/PhotoGrid';
import EditableProductName from '../../components/EditableProductName';

const demoProducts = [
    { "src": "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/default%2Fcandle.png?alt=media&token=47b612aa-5fe4-4c84-b8a9-759c2222323d", "name": "jar" },
    { "src": "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/default%2Fcleanser.png?alt=media&token=2c878c39-120a-487e-a742-38dc59d2a5c7", "name": "container" },
    { "src": "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/default%2Fserum.png?alt=media&token=153a8124-db59-444e-af68-0154e5fc8c70", "name": "bottle" },
    { "src": "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/default%2Fshoes.png?alt=media&token=02808bd6-90f5-4524-b011-f7ea6000c883", "name": "shoes" },
    { "src": "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/default%2Fskincare.png?alt=media&token=f144c3b1-e0a0-4ce5-ae5e-9c2fc3d50fd3", "name": "bottle" }
]

export const InstantBGEditor = ({
    isLoading,
    generateImages,
    handleProductAssetSelected,
    handleDemoFileSelected,
    availableImageCredits,
    onSelectedNumberOfImagesValuesChange,
    goBack
}) => {
    const initialStepsState = [
        { position: 0, completed: false, enabled: true, isSelected: true },
        { position: 1, completed: false, enabled: false, isSelected: false },
        { position: 2, completed: false, enabled: false, isSelected: false },
    ];

    const [steps, setSteps] = useState(initialStepsState);

    const [isProductModalVisible, setIsProductModalVisible] = useState(false);
    const productNameInputRef = useRef(null);
    const [isProductNameEmptylError, setIsProductNameEmptyError] = useState(false);
    const [areProductImageAndPromptEmpty, setAreProductImageAndPromptEmpty] = useState(false)
    const [product, setProduct] = useState({
        promptText: "",
        imageBase64: null,
        demoImageUrl: null,
        isDemoProduct: false,
        imageNumber: 1,
        name: "",
        fileName: ""
    });

    const [isPromptModalVisible, setIsPromptModalVisible] = useState(false);

    const [buttonArray, setButtonArray] = useState([
        { id: '1', value: 1, isSelected: true, label: '1' },
        { id: '2', value: 2, isSelected: false, label: '2' },
        { id: '3', value: 3, isSelected: false, label: '3' },
        { id: '4', value: 4, isSelected: false, label: '4' },
    ]);

    const handleFileSelected = (base64, fileName) => {
        if (base64 !== null) {
            handleProductAssetSelected(base64)
            setProduct({
                ...product,
                imageBase64: base64,
                fileName: fileName
            });
            setIsProductModalVisible(true)
        } else {
            handleProductAssetSelected(base64)
        }
    };

    const handleProductAssetNameChange = (e) => {
        setProduct({
            ...product,
            name: e.target.value
        })
    };

    const closeProductAssetModal = () => {
        if (product.name.length < 3) {
            setIsProductNameEmptyError(true);
            productNameInputRef.current.focus();
        } else {
            setIsProductNameEmptyError(false);
            setIsProductModalVisible(false);
            completeFirstStep()
        }
    };

    const completeFirstStep = () => {
        setStepCompleted(0)
        setStepEnabled(1)
        setStepSelected(1)
    }

    const onPromptTextChange = (newText) => {
        if (newText.length > 0) {
            setProduct({
                ...product,
                promptText: newText
            });
        }
    };

    const openPromptTextModal = () => {
        setIsPromptModalVisible(true);
    };

    const closePromptTextModal = () => {
        if (product.promptText.length > 5) {
            setIsPromptModalVisible(false);
            setStepCompleted(1)
            setStepEnabled(2)
            setStepSelected(2)
        }
    };

    const onSelectedValuesChange = (id) => {
        const newValue = buttonArray.find(button => button.id === id).value;

        if (onSelectedNumberOfImagesValuesChange(newValue)) {
            return;
        }

        setButtonArray(
            buttonArray.map(button =>
                button.id === id
                    ? { ...button, isSelected: true }
                    : { ...button, isSelected: false }
            )
        );

        setProduct({
            ...product,
            imageNumber: newValue
        });
    };

    const uploadImage = (e) => {
        if (product.isDemoProduct) {
            generateImages(product)
            return
        }

        if (product.imageBase64 == null || product.promptText.length === 0) {
            setAreProductImageAndPromptEmpty(true)
        } else {
            setAreProductImageAndPromptEmpty(false)
            generateImages(product)
        }
    };

    const setStepEnabled = (stepIndex) => {
        steps[stepIndex].enabled = true;
        setSteps(steps);
    }

    const setStepCompleted = (stepIndex) => {
        steps[stepIndex].completed = true;
        setSteps(steps);
    }

    const setStepSelected = (stepIndex) => {
        const newSteps = steps.map(step => ({ ...step, isSelected: false }));
        newSteps[stepIndex].isSelected = true;
        setSteps(newSteps);
    };

    const resetSelectedSteps = () => {
        setSteps([...initialStepsState]);
    }

    return (
        <div className="flex flex-col h-screen">
            {/* Step 1 */}
            {isProductModalVisible && (
                <div
                    className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-70 z-50"
                >
                    <div
                        className="flex flex-col bg-white p-6 rounded-md m-6 md:m-0 w-full md:w-1/2 lg:w-1/3 h-[80%] overflow-hidden"
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="flex-grow mb-4 w-full overflow-hidden">
                            <img src={product.imageBase64} alt="Selected" className="max-w-full max-h-full object-contain mx-auto" />
                        </div>
                        <div className="flex flex-col space-y-4">
                            <label className="block text-blue-900 font-semibold font-md">
                                Enter a name for the product:
                                <p className=' font-light text-sm'>Do not use the full description of the product. Use words like bag, bottle etc</p>
                                <input
                                    type="text"
                                    ref={productNameInputRef}
                                    value={product.name}
                                    onChange={handleProductAssetNameChange}
                                    required
                                    className={`mt-1 p-2 w-full border rounded-md ${isProductNameEmptylError ? 'border-red-500' : ''}`}
                                />
                                {isProductNameEmptylError && <span className="text-red-500 text-sm mt-1">Product name must be at least 3 characters long.</span>}
                            </label>
                            <div className="flex justify-between">
                                {/* <button
                                onClick={handleRemoveBackground}
                                className="px-4 py-2 bg-red-500 text-white rounded-md"
                            >
                                Remove Background
                            </button> */}
                                <button
                                    onClick={closeProductAssetModal}
                                    className="ml-auto px-6 py-2 border border-crayola-900 text-crayola-900 rounded-md hover:text-white hover:bg-crayola-700"
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isPromptModalVisible && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-70 z-50">
                    <div className="flex flex-col bg-white p-6 rounded-md w-full md:w-1/2 h-screen m-6 md:m-0 relative">
                        <h2 className="text-2xl font-bold mb-4">Choose or Enter Prompt</h2>
                        <div className="flex-none">
                            <InputWithTitle title="Enter prompt" productName={product.name} content={product.promptText} onValueChange={onPromptTextChange} />
                        </div>
                        <div className="text-center my-4 text-xl font-semibold flex-none">OR</div>
                        <div className="overflow-y-auto flex-grow">
                            <div className="PhotoGrid">
                                <PhotoGrid name={product.name} onPhotoSelect={onPromptTextChange} />
                            </div>
                        </div>

                        <div className="mt-4 ml-auto">
                            <button
                                className={`px-6 py-2 border border-crayola-900 text-crayola-900 rounded-md hover:text-white hover:bg-crayola-700 ${product.promptText.length > 5 ? '' : 'opacity-50 cursor-not-allowed'}`}
                                onClick={closePromptTextModal}
                                disabled={product != null && product.promptText.length <= 5}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className='flex border-b pb-2'>
                <div onClick={goBack} className='flex items-center cursor-pointer h-8 ml-2 hover:text-blue-950'>
                    <AiOutlineLeft className='text-blue-900' />
                    <p className="ml-1 text-blue-900 font-semibold text-sm">Instant Background</p>
                </div>
                <div
                    className='flex items-center ml-auto text-xs font-semibold text-white bg-blue-900 rounded-full px-2'
                    data-tooltip-content='PRO feature (1 credit per image)'
                    data-tooltip-id='pro-tooltip'
                >
                    <AiOutlineCrown size={16} className='mr-1' />
                    PRO
                </div>
                <Tooltip className='z-10' id='pro-tooltip' place='top' effect='solid' />
            </div>
            {/* Step 1 */}
            <div
                className={`mt-2 p-4 border-b-2 border-gray-50 ${steps[0].enabled ? '' : 'bg-gray-200 opacity-50 '} `}
                onClick={(e) => {
                    if (steps[0].enabled) {
                        setStepSelected(0)
                    }
                }}
            >
                <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                        <span className="rounded-full bg-blue-500 text-white w-6 h-6 flex items-center justify-center">1</span>
                        <span className="font-semibold ml-2">Product Asset Image</span>
                    </div>
                    <div>{steps[0].isSelected ? <AiOutlineUp /> : <AiOutlineDown />}</div>
                </div>
                {steps[0].isSelected && <div>
                    {product != null && product.name &&
                        <EditableProductName
                            initialName={product.name}
                            onSave={(name) => {
                                setProduct({ ...product, name: name })
                            }}
                        />
                    }
                    <ImageUploadComponent
                        fileName={product.fileName}
                        onFileDelete={(e) => {
                            e.stopPropagation()
                            setProduct({
                                ...product,
                                fileName: null,
                                imageBase64: null,
                                name: null,
                                promptText: "",
                                imageNumber: 1,
                                isDemoProduct: false,
                                demoImageUrl: null
                            })
                            setButtonArray(
                                buttonArray.map(button =>
                                    button.id === "1"
                                        ? { ...button, isSelected: true }
                                        : { ...button, isSelected: false }
                                )
                            );
                            handleProductAssetSelected(null)
                            resetSelectedSteps()
                        }
                        }
                        onFileSelected={handleFileSelected} />
                    <div className='text-center my-2'>
                        <p className='text-xs'>No photo? Try one of these:</p>
                        <div className='flex justify-center my-2'>
                            {demoProducts.map((photo, idx) => {
                                return <img
                                    className='border-2 hover:opacity-70 rounded-lg mx-1 w-10 h-10 cursor-pointer'
                                    key={idx}
                                    src={photo.src}
                                    alt={photo.name}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setProduct({
                                            ...product,
                                            name: photo.name,
                                            imageBase64: null,
                                            fileName: "",
                                            demoImageUrl: photo.src,
                                            isDemoProduct: true
                                        })
                                        handleDemoFileSelected(photo.src)
                                        completeFirstStep()
                                    }}
                                />
                            })}
                        </div>
                    </div>
                </div>}
            </div>

            {/* Step 2 */}
            <div
                className={`p-4 border-b-2 border-gray-50 ${steps[1].enabled ? '' : 'bg-gray-200 opacity-50'}`}
                onClick={() => {
                    if (steps[1].enabled) {
                        setStepSelected(1)
                    }
                }}
            >
                <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                        <span className="rounded-full bg-blue-500 text-white w-6 h-6 flex items-center justify-center">2</span>
                        <span className="font-semibold ml-2">Enter Prompt Text</span>
                    </div>
                    <div>{steps[1].isSelected ? <AiOutlineUp /> : <AiOutlineDown />}</div>
                </div>
                {steps[1].isSelected && <div className='flex flex-col'>
                    {product != null && product.promptText != null && product.promptText.length > 0 && <div before={product.name} className='text-ellipsis bg-white rounded-lg p-4 before:bg-blue-900 before:text-white before:rounded-lg before:px-2 before:py-1 before:content-[attr(before)] '>{product.promptText}</div>}
                    <button onClick={openPromptTextModal} className="text-blue-600 underline mt-2">
                        Choose or enter prompt
                    </button>

                </div>}
            </div>

            {/* Step 3 */}
            <div
                className={`p-4 border-b-2 border-gray-50 ${steps[2].enabled ? '' : 'bg-gray-200 opacity-50'}`}
                onClick={() => {
                    if (steps[2].enabled) {
                        setStepSelected(2)
                    }
                }}
            >
                <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                        <span className="rounded-full bg-blue-500 text-white w-6 h-6 flex items-center justify-center">3</span>
                        <span className="font-semibold ml-2">Number Of Image Results</span>
                    </div>
                    <div>{steps[2].isSelected ? <AiOutlineUp /> : <AiOutlineDown />}</div>
                </div>
                {steps[2].isSelected && <ButtonGroup title="" buttons={buttonArray} direction="horizontal" onSelectedValuesChange={onSelectedValuesChange} />}
            </div>

            <div className="mt-auto shadow-lg">
                <button onClick={(e) => uploadImage(e)} className='w-full flex justify-center bg-gradient-to-r border border-crayola-800 from-crayola-700 to-crayola-800 hover:bg-gradient-to-r hover:from-crayola-800 hover:to-crayola-900 text-white rounded-md outline-none py-1' enabled={isLoading || areProductImageAndPromptEmpty}>
                    {isLoading ?
                        <div className='flex justify-center items-center'>
                            <svg className="h-8 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span className="font-medium ml-2">Creating...please wait </span>
                        </div>
                        :
                        <div className="flex items-center font-medium"><AiOutlineRobot
                            className='h-8 w-5 mr-2'
                        />Generate</div>}
                </button>

            </div>
            {<span className='my-4 text-center text-xs'>Available Image Credits: {availableImageCredits}</span>}
        </div>
    );
};
