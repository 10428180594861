import React, { useState } from 'react';
import { AiOutlineFullscreen } from 'react-icons/ai';
import ImageModal from './ImageModal';


const photos = [
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fperfume-bottle-result-3.jpeg?alt=media&token=356f0a13-f2e3-47b8-a8fa-37f9f26f9d22", productName: "${productName}", prompt: "on a marble countertop in front of moonlit lake" },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fwine-bottle-result.jpeg?alt=media&token=7a497be8-6f0f-4d17-90b7-54f13231a39d", productName: "${productName}", prompt: "on a rock surrounded by trees, in front of a canyon++ and river++" },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fvase-result-1.jpeg?alt=media&token=0650beeb-d03a-44fe-993a-b0e1a4184deb", productName: "${productName}", prompt: "showcased on a sleek marble countertop, with the ambiance of a chic art gallery surrounding it, reflecting contemporary elegance and aesthetic appeal." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fshampoo-bottle-result.jpeg?alt=media&token=9399d26d-a928-4776-8ee8-1227aaf2af88", productName: "${productName}", prompt: "on a platform surrounded by flowers and leaves, in front of sunset and the ocean in the background" },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fwine-bottle-result-5.jpeg?alt=media&token=0d543ef6-c1d6-4975-8b55-eee654862f8c", productName: "${productName}", prompt: "positioned on a polished slate coaster set atop a mahogany bar counter nestled amidst a backdrop of golden barley fields, showcasing its roots, with the majestic Scottish Highlands in the distance and the setting sun casting a warm golden hue over everything." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Frunning-shoes-result.jpeg?alt=media&token=fb797e8b-a64c-4fcd-a89f-3f627fbe080e", productName: "${productName}", prompt: "positioned on a moss-covered log, and a secluded forest path blanketed with fallen autumn leaves in the background. The golden hues of the season paint the trees, while the distant murmur of migrating birds adds to the tranquil ambiance overhead." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fhat-result-3.jpeg?alt=media&token=51fc98ec-00e8-4da5-a115-46934972ca49", productName: "${productName}", prompt: "on a beachfront table, with a backdrop of turquoise waves crashing and sunset" },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fbag-result-5.jpeg?alt=media&token=c5a6ca8d-ca7f-4a4c-8d3e-9b3f0ab4373e", productName: "${productName}", prompt: "strategically positioned on a wooden jetty, with the serene backdrop of a glimmering lake, and a setting sun casting a golden hue." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fserum-result-2.jpeg?alt=media&token=0512d0e5-a452-4edf-9b1c-5f95e0f26090", productName: "${productName}", prompt: "on a wooden platform, adorned with a beautiful flower+ and surrounded by colorful decorative elements and greenery." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fbody-lotion-result-2.jpeg?alt=media&token=0ec9737e-141f-455a-952e-ff51d625c2a9", productName: "${productName}", prompt: "on an elegant marble++ countertop, surrounded by fresh botanicals and green plants, with sun rays filtering through window and illuminating its sleek design." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fcandle-result-4.jpeg?alt=media&token=fadb7248-33a1-403f-bc07-0e90294934ef", productName: "${productName}", prompt: "on a circular marble platform surrounded by smoke+" },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fcandle-2-result-1.jpeg?alt=media&token=d16c67cc-870e-42c3-98b6-562fd6ea775a", productName: "${productName}", prompt: "poised gracefully on a circular marble platform, within a dimly lit relaxation room. Shadows dance on the walls as the candlelight reflects off polished stone surfaces, all while the gentle hum of a Himalayan salt lamp adds to the ambience." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fbath-salts-result.jpeg?alt=media&token=095f104c-4207-4e24-88a1-35c6727a466d", productName: "${productName}", prompt: "positioned on a lustrous marbled counter, in front of window with blue skies." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fface-cream-result.jpeg?alt=media&token=139994cb-486a-48c7-a0da-f67fda911a05", productName: "${productName}", prompt: "elegantly placed on a marble ledge, framed by a sunny window, whispering secrets of ageless beauty." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fskincare-result-5.jpeg?alt=media&token=e791182b-6570-49d3-b1d1-f4d1bc0a852a", productName: "${productName}", prompt: "elegantly displayed on a platform bathed in pastel hues, reflecting a dance of light and luxury in every facet." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fvase-result-3.jpeg?alt=media&token=f5913075-d442-44cd-9869-7170ecd2b8da", productName: "${productName}", prompt: "positioned elegantly on a geometric metal stand, set against a backdrop of a monochrome, textured accent wall in a chic urban loft." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fgym-shoes-result-3.jpeg?alt=media&token=eb080530-d01a-437a-97ad-1575d2544ab6", productName: "${productName}", prompt: "strategically placed on a rooftop workout area, with a breathtaking view of a bustling city skyline at dusk, blending the realms of fitness and urban life." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fcbd-result-7.jpeg?alt=media&token=e47d3524-2913-4a7e-95e5-389426eb12fc", productName: "${productName}", prompt: "showcased on a (round++ purple+ platform++)++, with a purple++ wall++ behind." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fcleanser-result-10.jpeg?alt=media&token=7a3bf6d1-9723-4eb2-b2a7-be8f63d7dc09", productName: "${productName}", prompt: "strategically positioned on a sleek surface, with a water+ splash+ in the background." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Ftoner-result-3.jpeg?alt=media&token=85f58c45-3671-482b-9cce-2aa581dcdc43", productName: "${productName}", prompt: "gracefully positioned on a gleaming marble platform, adorned with scattered white rose petals, set against the backdrop of a serene, sunlit rose garden." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fsofa-result-1.jpeg?alt=media&token=af9bae63-dad0-4977-ad37-f78b4980c501", productName: "${productName}", prompt: "nestled within a minimalist living space, with polished concrete floors, soft white drapes, and a single piece of abstract art creating a statement." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fmug-result-2.jpeg?alt=media&token=960d179f-157c-41c9-8343-891e85372fe8", productName: "${productName}", prompt: "cradled on a rustic wooden coffee table, steam wafting upwards. The backdrop captures a snug living room, aglow from the crackling fireplace, with plush, amber-toned throw pillows and the soft strumming of an acoustic guitar resting against a worn leather armchair." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fchair-result-2.jpeg?alt=media&token=9019cef4-1b04-4a97-b785-d99925cc3318", productName: "${productName}", prompt: "set against expansive floor-to-ceiling windows framed in dark mahogany. The panoramic views of a bustling cityscape are accentuated by twilight's blue and gold streaks. A modern coffee table made of glass and metal sits in front, adorned with glossy magazines and a minimalistic vase bearing fresh lilies." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fvase-result-7.jpeg?alt=media&token=e8bdc510-4cc6-4611-aa4e-b51167e6334c", productName: "${productName}", prompt: "resting atop a polished concrete counter, illuminated by the soft glow of designer pendant lights, capturing the fusion of industrial edge and refined aesthetics." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Ftoner-result-8.jpeg?alt=media&token=12aaa61e-f8b2-4947-8ab8-ee24b43d8cfd", productName: "${productName}", prompt: "gracefully resting on a surface+, surrounded by (swirls of ethereal vapor)++, reminiscent of a dreamy cloud-scape." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Ftoy-result-1.jpeg?alt=media&token=66780a0a-d098-4910-af55-3aade87228c0", productName: "${productName}", prompt: "playfully perched on a vintage wooden child's desk, surrounded by classic storybooks with worn edges. The background captures a nostalgic child's room, complete with pastel-colored wallpaper, dainty bunting, and a softly glowing nightlight casting whimsical shadows." },
    { src: "https://firebasestorage.googleapis.com/v0/b/wordlab.appspot.com/o/prompts%2Fwatch-2-result-3.jpeg?alt=media&token=203561d6-6820-4294-8e14-b58b7c8abd43", productName: "${productName}", prompt: "on a sleek black marble platform, set against a backdrop of radiant gold flares, emphasizing its unparalleled craftsmanship and value." },
];

function PhotoGrid({ name, onPhotoSelect }) {

    const updatedPhotos = photos.map(photo => ({
        ...photo,
        productname: name
    }));

    const [isImageModalOpen, setImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState("");
    const [prompt, setModalPrompt] = useState("")
    const [productName, setProductName] = useState("")

    const handleZoomClick = (photoSrc, prompt, productName) => {
        setModalImageSrc(photoSrc);
        setModalPrompt(prompt)
        setProductName(productName)
        setImageModalOpen(true);
    }

    const handleCloseModal = () => {
        setImageModalOpen(false);
        setModalImageSrc("");
    }

    const handlePromptSelect = (prompt) => {
        onPhotoSelect(prompt);
        setImageModalOpen(false);
    };

    return (
        <div>
            <p className="ml-2 text-sm font-semibold text-blue-900">Select a prompt template</p>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-8 p-4">
                {updatedPhotos.map((photo, index) => (
                    <div
                        key={index}
                        className="relative group cursor-pointer transform transition-transform duration-300 hover:scale-105"
                        onClick={() => onPhotoSelect(photo.prompt)}
                    >
                        <img
                            src={photo.src}
                            alt="prompt template"
                            className="w-full h-60 object-cover rounded-lg"
                        />

                        {/* Zoom Icon */}
                        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            <AiOutlineFullscreen
                                size={32}
                                className=' text-crayola-900'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleZoomClick(photo.src, photo.prompt, photo.productname);
                                }}
                            />
                        </div>

                        {/* Prompt Text */}
                        {/* <div className="absolute bottom-0 left-0 w-full bg-black text-sm bg-opacity-70 text-white px-2 py-1 rounded-lg group-hover:opacity-100 opacity-0 transition-opacity duration-300 shadow-lg">
                            <p className="">{photo.prompt}</p>
                        </div> */}
                    </div>
                ))}
                <ImageModal
                    isOpen={isImageModalOpen}
                    imageSrc={modalImageSrc}
                    productName={productName}
                    prompt={prompt}
                    onClose={handleCloseModal}
                    onPromptSelect={handlePromptSelect}
                />
            </div>
        </div >
    );
}






export default PhotoGrid
