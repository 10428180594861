import { firestoreDB } from "../../api/Firebase";
import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../Session';

export const useGetAvailableImageCredits = () => {
    const { initializing, user } = useAuth();
    const [availableImageCredits, setAvailableImageCredits] = useState(0);

    useEffect(() => {
        if (!user) return; // ensure user is available

        const docRef = doc(firestoreDB, "users", user.uid);

        // Use onSnapshot for real-time updates
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (doc.exists()) {
                setAvailableImageCredits(doc.data().imageCredits);
            } else {
                setAvailableImageCredits(0);
            }
        }, (error) => {
            console.error("Error fetching image credits:", error);
            setAvailableImageCredits(0);
        });

        // Cleanup listener on component unmount
        return () => unsubscribe();
    }, [user])

    return availableImageCredits;
}

